//ApiService
import axios from 'axios';
import {
    hideLoading,
    showLoading,
    showToastMessage,
    // hideToastMessage,
} from '../utils/utils';
import * as LocalStore from '../store/LocalStore';

const instance = axios.create({
    baseURL: '',
    timeout: 10000,
    // headers: {'X-Custom-Header': 'foobar'}
    // headers: {
    //     Authorization: 'Bearer ' + window.localStorage.getItem('userId'),
    // },
});
instance.defaults.validateStatus = function (status) {
    // return status >= 200 && status < 300; // default
    return status == 200 || status == 500 || status == 404 || status == 401;
};
instance.interceptors.request.use(
    function (config) {
        const authData = LocalStore.getAuthData() || '';
        let deviceData = LocalStore.getDeviceData() || '';

        config.headers.Authorization = 'Bearer ' + authData + ' ' + deviceData;

        showLoading(10, true);
        return config;
    },
    function (error) {
        console.log(error);
        hideLoading();
        showToastMessage(error.message);
        return Promise.reject(error);
    },
);
instance.interceptors.response.use(
    function (response) {
        hideLoading();
        if (response.status === 500) {
            // const msg = response.data.message || response.statusText;
            const msg =
                response.data.message ||
                '서버 연결 오류 입니다. 잠시후 다시 시도해 주세요.';
            showToastMessage(msg, -1);
            return Promise.reject(response.data);
            // throw new Error(response.data.message);
        }
        if (response.status === 401) {
            // const msg = response.data.message || response.statusText;
            const msg =
                response.data.message ||
                '사용자 인증 오류 입니다.|로그인 후 다시 시도해 주세요.';
            // removeUserId();
            setTimeout(() => {
                showToastMessage(msg, 2);
            }, 1000);

            return Promise.reject(response.data);
            // throw new Error(response.data.message);
        }
        return response;
    },
    function (error) {
        console.log(error);
        hideLoading();
        showToastMessage(
            '서버 데이터 요청 오류 입니다. 잠시후 다시 시도해 주세요.',
            -1,
        );
        return Promise.reject(error);
    },
);

// const apiServerUrl = 'https://api.tosomebody.com/api/v1';
const apiServerUrl = '/api/v1';

// export const getStoryList = async (pageNo = 1) => {
//     return await instance({
//         url: `${apiServerUrl}/admin/listStory/${pageNo}`,
//         method: 'get',
//     })
//         .then(res => {
//             return res.data.data || null;
//         })
//         .catch(() => {
//             return null;
//         });
// };

export const getStoryList = async (formData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/listStory`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getSearchStory = async (formData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/getSearchStory`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return false;
        });
};

export const updateStory = async (formData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/updateStory`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return false;
        });
};

export const deleteStory = async (storyNo = 0) => {
    return await instance({
        url: `${apiServerUrl}/admin/deleteStory/${storyNo}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const deleteReply = async (replyNo = 0, storyNo = 0) => {
    return await instance({
        url: `${apiServerUrl}/admin/deleteReply/${replyNo}/${storyNo}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const saveNewStory = async (formData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/newStory`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return false;
        });
};

export const getDetail = async (storyID: number) => {
    return await instance({
        url: `${apiServerUrl}/admin/StoryDetail/${storyID}`,
        method: 'get',
    })
        .then(res => {
            console.log(res.data.data);
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getNewAddrKey = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/getNewAddrKey`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const updateStoryListSort = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/updateStoryListSort`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return false;
        });
};

export const updateExpiredStory = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/updateExpiredStory`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return false;
        });
};
export const moveDeletedStory = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/moveDeletedStory`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return false;
        });
};

export const makeKeyword = async (contents: string) => {
    return await instance({
        url: `${apiServerUrl}/admin/makeKeyword`,
        data: {
            content: contents,
        },
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
        method: 'post',
    })
        .then(res => {
            return res.data.data || [];
        })
        .catch(() => {
            return [];
        });
};

export const getUserList = async (
    pageNo = 1,
    perPage = 20,
    searchWord = '',
) => {
    return await instance({
        url: `${apiServerUrl}/admin/listUsers/${pageNo}/${perPage}/${searchWord}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getStoryReportList = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/StoryReportList`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getStoryReportDetail = async (storyNo = 1) => {
    return await instance({
        url: `${apiServerUrl}/admin/StoryReportDetail/${storyNo}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getReportReplyList = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/ReportReplyList`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const banStory = async (storyNo = 0) => {
    return await instance({
        url: `${apiServerUrl}/admin/banStory/${storyNo}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getAdsInfoList = async (pageNo = 1, perPage = 20) => {
    return await instance({
        url: `${apiServerUrl}/admin/getAdsInfoList/${pageNo}/${perPage}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getEmoticonList = async () => {
    return await instance({
        url: `${apiServerUrl}/listEmoticons`,
        method: 'get',
    })
        .then(res => {
            if (res.data.data) {
                res.data.data.forEach((item: any) => {
                    // url item.emoticon_file 의 맨 앞 도메인 제거
                    const currUrl = item.emoticon_file;
                    const currPath = currUrl.split('/');
                    if (currPath.length > 3) {
                        item.emoticon_file = '/' + currPath.slice(3).join('/');
                    }

                    // item.emoticon_file = item.emoticon_file.replace('http://tosomebody.com', '');
                });
            }

            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const getAdvertisersList = async (pageNo = 1, perPage = 20) => {
    return await instance({
        url: `${apiServerUrl}/admin/getAdvertisersList/${pageNo}/${perPage}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const setAdvertiser = async (formData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/setAdvertiser`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const setAds = async (formData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/setAds`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const checkLogin = async () => {
    // 인증정보가 없으면 null 리턴
    const authData = LocalStore.getAuthData();
    const deviceData = LocalStore.getDeviceData();
    if (!authData || !deviceData) {
        return null;
    }

    return await instance({
        url: `${apiServerUrl}/admin/checkLogin`,
        method: 'get',
    })
        .then(res => {
            if (res.data.data !== null) {
                LocalStore.setAuthData(res.data.data);
            }
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};

export const login = async (formData: any) => {
    const deviceData = LocalStore.getDeviceData();
    formData.deviceData = deviceData;

    return await instance({
        url: `${apiServerUrl}/admin/login`,
        data: formData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            if (res.data.data !== null) {
                LocalStore.setAuthData(res.data.data);
            }
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};
export const logout = () => {
    LocalStore.logout();
    return true;
};

export const getReplyList = async (storyId: number) => {
    return await instance({
        url: `${apiServerUrl}/admin/ReplyList/${storyId}`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || [];
        })
        .catch(() => {
            return null;
        });
};

export const sendPushMessage = async (sendData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/sendPushMessage`,
        data: sendData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return Number(res.data.data) || 0;
        })
        .catch(() => {
            return 0;
        });
};

export const getNoticeList = async () => {
    return await instance({
        url: `${apiServerUrl}/admin/getNoticeList`,
        method: 'get',
    })
        .then(res => {
            return res.data.data || [];
        })
        .catch(() => {
            return null;
        });
};
export const writeNotice = async (jsonData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/writeNotice`,
        data: jsonData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};
export const deleteNotice = async (noticeId: number) => {
    return await instance({
        url: `${apiServerUrl}/admin/deleteNotice/${noticeId}`,
        method: 'delete',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};
export const getQnaList = async (pageNo = 1, perPage = 20) => {
    return await instance({
        url: `${apiServerUrl}/admin/getQnaListAdmin/${pageNo}/${perPage}`,
        method: 'get',
    })
        .then(res => {
            return (
                res.data.data || {
                    totalCnt: 0,
                    userList: [],
                }
            );
        })
        .catch(() => {
            return {
                totalCnt: 0,
                userList: [],
            };
        });
};
export const writeQnaReply = async (jsonData: any) => {
    return await instance({
        url: `${apiServerUrl}/admin/writeQnaReplyAdmin`,
        data: jsonData,
        method: 'post',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
        },
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};
export const deleteQna = async (qnaNo: number) => {
    return await instance({
        url: `${apiServerUrl}/admin/deleteQnaAdmin/${qnaNo}`,
        method: 'delete',
    })
        .then(res => {
            return res.data.data || null;
        })
        .catch(() => {
            return null;
        });
};
