import { observable } from 'mobx';
import { IadminInfo } from '../const/types';

const adminInfo = observable({
    dataset: {
        name: '',
        id: '',
        leftMenu: '',
    } as IadminInfo,

    setList(data: IadminInfo) {
        this.dataset = data;
    },

    setName(name: string) {
        this.setList({
            ...this.dataset,
            name: name,
        });
    },
    getName() {
        return this.dataset.name;
    },
    setId(id: string) {
        this.setList({
            ...this.dataset,
            id: id,
        });
    },
    getId() {
        return this.dataset.id;
    },
    setLeftMenu(leftMenu: string) {
        this.setList({
            ...this.dataset,
            leftMenu: leftMenu,
        });
    },
    getLeftMenu() {
        return this.dataset.leftMenu;
    },
});

export default adminInfo;
