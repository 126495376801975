import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect, useState } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/notice.scss';
import { Iuser, IusersPage } from '../../const/types';
import { set } from 'mobx';
import { send } from 'process';

type TnoticeDetail = {
    notice_no: number;
    subject: string;
    content: string;
    reg_date: string;
    mod_date: string;
    view_cnt: number;
    use_yn: string;
};

const Notice = observer(() => {
    const getDefaultDetail = (): TnoticeDetail => {
        let today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        const hours = today.getHours();
        let minutes = today.getMinutes();
        const autoDateTime = `${year}-${month < 10 ? '0' + month : month}-${
            date < 10 ? '0' + date : date
        } ${hours < 10 ? '0' + hours : hours}:${
            minutes < 10 ? '0' + minutes : minutes
        }:00`;

        return {
            notice_no: 0,
            subject: '',
            content: '',
            reg_date: autoDateTime,
            mod_date: autoDateTime,
            view_cnt: 0,
            use_yn: 'N',
        } as TnoticeDetail;
    };

    const { adminInfo } = store;
    const [noticeList, setNoticeList] = useState<TnoticeDetail[]>([]);
    const [noticeDetail, setNoticeDetail] =
        useState<TnoticeDetail>(getDefaultDetail());

    useEffect(() => {
        getNoticeList();
    }, []);

    const getNoticeList = async () => {
        const noticeList = await ApiService.getNoticeList();
        setNoticeList(noticeList);
    };

    const selectNotice = (noticeNo: number) => {
        const notice = noticeList.find(notice => notice.notice_no === noticeNo);
        console.log(notice);
        if (!notice) {
            return;
        }
        setNoticeDetail(notice);
    };

    const resetDetailForm = () => {
        let today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        const hours = today.getHours();
        let minutes = today.getMinutes();
        const autoDateTime = `${year}-${month < 10 ? '0' + month : month}-${
            date < 10 ? '0' + date : date
        } ${hours < 10 ? '0' + hours : hours}:${
            minutes < 10 ? '0' + minutes : minutes
        }:00`;

        setNoticeDetail(getDefaultDetail());
    };

    const newNotice = () => {
        resetDetailForm();

        const subjectForm = document.getElementById(
            'idx_notice_title',
        ) as HTMLInputElement;
        subjectForm.focus();
    };

    const saveNotice = async () => {
        console.log('saveNotice');
        const result = await ApiService.writeNotice(noticeDetail);
        if (result) {
            getNoticeList();
            resetDetailForm();
        } else {
            utils.showToastMessage('저장에 실패했습니다.');
        }
    };

    const deleteNotice = async () => {
        console.log('deleteNotice');

        if (
            confirm(
                `선택된 공지글 (No. ${noticeDetail.notice_no})정말 삭제하시겠습니까?`,
            ) === false
        ) {
            return;
        }

        const result = await ApiService.deleteNotice(noticeDetail.notice_no);
        if (result) {
            getNoticeList();
            resetDetailForm();
        } else {
            utils.showToastMessage('삭제에 실패했습니다.');
        }
    };

    return (
        <div className="body-content">
            <table className="notice-list">
                <colgroup>
                    <col width="20" />
                    <col width="150" />
                    <col width="*" />
                    <col width="150" />
                    <col width="150" />
                    <col width="70" />
                    <col width="70" />
                </colgroup>

                <tr>
                    <th>NO</th>
                    <th>제목</th>
                    <th>내용</th>
                    <th>등록일</th>
                    <th>수정일</th>
                    <th>조회수</th>
                    <th>공개</th>
                </tr>
                {noticeList.map(notice => {
                    return (
                        <tr
                            key={notice.notice_no}
                            className={
                                (notice.use_yn == 'Y' ? 'active' : 'inactive') +
                                (notice.notice_no === noticeDetail.notice_no
                                    ? ' selected'
                                    : '')
                            }
                            onClick={() => selectNotice(notice.notice_no)}
                        >
                            <td>{notice.notice_no}</td>
                            <td className="subject">{notice.subject}</td>
                            <td className="content">{notice.content}</td>
                            <td>{notice.reg_date}</td>
                            <td>
                                {notice.reg_date !== notice.mod_date
                                    ? notice.mod_date
                                    : '-'}
                            </td>
                            <td>{notice.view_cnt}</td>
                            <td>{notice.use_yn == 'Y' ? '공개' : '비공개'}</td>
                        </tr>
                    );
                })}
            </table>
            <table className="user-list-bottom">
                <tr>
                    <td className="left">
                        {/* <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button> */}
                    </td>
                </tr>
            </table>

            <div className="notice-detail">
                <div className="line title">상세 내용</div>
                <div className="line">
                    <div className="head">작업</div>
                    <div className="body">
                        <div className="text">
                            {noticeDetail.notice_no === 0 ? '신규' : `수정`}
                        </div>
                        {noticeDetail.notice_no > 0 && (
                            <div className="split-bottons">
                                <button
                                    className="new-notice"
                                    onClick={newNotice}
                                >
                                    새로 등록하기
                                </button>
                                <button
                                    className="delete-notice"
                                    onClick={deleteNotice}
                                >
                                    삭제하기
                                </button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="line">
                    <div className="head">번호</div>
                    <div className="body">
                        <input
                            type="text"
                            id="idx_notice_no"
                            className="notice-no"
                            value={noticeDetail.notice_no}
                            readOnly
                        />
                    </div>
                </div>
                <div className="line">
                    <div className="head">제목</div>
                    <div className="body">
                        <input
                            type="text"
                            id="idx_notice_title"
                            className="subject"
                            value={noticeDetail.subject}
                            onChange={e => {
                                setNoticeDetail({
                                    ...noticeDetail,
                                    subject: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="line">
                    <div className="head">내용</div>
                    <div className="body">
                        <textarea
                            id="idx_notice_content"
                            className="content"
                            value={noticeDetail.content}
                            onChange={e => {
                                setNoticeDetail({
                                    ...noticeDetail,
                                    content: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="line">
                    <div className="head">등록일</div>
                    <div className="body">
                        <input
                            type="datetime-local"
                            id="idx_notice_reg_date"
                            className="reg-date"
                            value={noticeDetail.reg_date}
                            onChange={e => {
                                setNoticeDetail({
                                    ...noticeDetail,
                                    reg_date: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>
                <div className="line">
                    <div className="head">수정일</div>
                    <div className="body">
                        <input
                            type="datetime-local"
                            id="idx_notice_mod_date"
                            className="reg-date"
                            value={noticeDetail.mod_date}
                            onChange={e => {
                                setNoticeDetail({
                                    ...noticeDetail,
                                    mod_date: e.target.value,
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="line">
                    <div className="head">공개여부</div>
                    <div className="body">
                        <select
                            id="idx_notice_use_yn"
                            className="use-yn"
                            value={noticeDetail.use_yn}
                            onChange={e => {
                                setNoticeDetail({
                                    ...noticeDetail,
                                    use_yn: e.target.value,
                                });
                            }}
                        >
                            <option value="Y">공개</option>
                            <option value="N">비공개</option>
                        </select>
                    </div>
                </div>

                <div className="line">
                    <div className="head">조회수</div>
                    <div className="body">
                        <input
                            type="text"
                            id="idx_notice_view_cnt"
                            className="view-cnt"
                            value={noticeDetail.view_cnt}
                            onChange={e => {
                                setNoticeDetail({
                                    ...noticeDetail,
                                    view_cnt: Number(e.target.value),
                                });
                            }}
                        />
                    </div>
                </div>

                <div className="line">
                    <div className="head"></div>
                    <div className="body">
                        <button onClick={saveNotice}>저장하기</button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Notice;
