import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/ads-list.scss';
import { Iuser, IusersPage } from '../../const/types';
import { set } from 'mobx';
import { get } from 'jquery';

const AdsList = observer(() => {
    const { adminInfo } = store;

    const [adsList, setAdsList] = React.useState([] as any[]);
    const [adsInfo, setAdsInfo] = React.useState(null as any);
    const [companyInfo, setCompanyInfo] = React.useState(null as any);
    const [companyList, setCompanyList] = React.useState([] as any[]);
    const [currPage, setCurrPage] = React.useState(1);
    const [nextPage, setNextPage] = React.useState(1);
    const [totalCnt, setTotalCnt] = React.useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = React.useState(setPerPageList[0]);

    useEffect(() => {
        getUserList();
    }, []);

    const getUserList = async (page = 1, perPage = numPerPage) => {
        const dataInfo: IusersPage = await ApiService.getAdsInfoList(
            page,
            perPage,
        );
        if (dataInfo && dataInfo.list) {
            if (dataInfo.list.length > 0) {
                console.log(dataInfo.list);
                setAdsList(dataInfo.list);
            }
            setCurrPage(dataInfo.currentPage);
            setTotalCnt(dataInfo.totalCnt);
            setNextPage(dataInfo.nextPage);
        }
    };

    const movePage = (page: number) => {
        getUserList(page);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        getUserList(1, perPage);
    };

    const goDirectPage = () => {
        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        const page = parseInt(idx.value);
        if (page > 0 && page <= Math.ceil(totalCnt / numPerPage)) {
            getUserList(page);
        } else {
            alert(
                '잘못된 페이지 번호입니다.\n마지막 페이지는 ' +
                    Math.ceil(totalCnt / numPerPage) +
                    ' 페이지 입니다.',
            );
            idx.value = '1';
        }
    };

    const getCompanyList = async () => {
        if (companyList.length > 0) {
            return;
        }
        const newCompanyList = await ApiService.getAdvertisersList();
        if (newCompanyList.list.length > 0) {
            setCompanyList(newCompanyList.list);
        }
    };

    const showAdsInfoForm = () => {
        console.log('showAdsInfoForm');
        const adsInfoContainer = document.querySelector(
            '.ads-info-container',
        ) as HTMLElement;
        if (adsInfoContainer) {
            adsInfoContainer.style.display = 'block';
            getCompanyList();
        }
    };

    const clearAdsInfoForm = () => {
        const adsNo = document.getElementById('idx_ads_no') as HTMLInputElement;
        adsNo.value = '0';

        const title = document.getElementById('idx_title') as HTMLInputElement;
        if (title) title.value = '';

        const content = document.getElementById(
            'idx_content',
        ) as HTMLInputElement;
        if (content) content.value = '';

        const adsLinkUrl = document.getElementById(
            'idx_ads_link_url',
        ) as HTMLInputElement;
        if (adsLinkUrl) adsLinkUrl.value = '';

        const popupLinkUrl = document.getElementById(
            'idx_popup_link_url',
        ) as HTMLInputElement;
        if (popupLinkUrl) popupLinkUrl.value = '';

        const startDate = document.getElementById(
            'idx_start_date',
        ) as HTMLInputElement;
        if (startDate) startDate.value = '';

        const endDate = document.getElementById(
            'idx_end_date',
        ) as HTMLInputElement;
        if (endDate) endDate.value = '';

        const adsPosition = document.getElementById(
            'idx_ads_position',
        ) as HTMLInputElement;
        if (adsPosition) adsPosition.value = '';

        const active = document.getElementById('active') as HTMLInputElement;
        if (active) active.checked = true;
    };

    const addAdsInfo = async () => {
        console.log('addAdsInfo');

        showAdsInfoForm();
        clearAdsInfoForm();
        setAdsInfo(null);

        const title = document.getElementById('idx_title') as HTMLInputElement;
        if (title) title.focus();
    };

    const loadAdsInfo = async (adsNo: number, event: any) => {
        console.log('loadAdsInfo');

        // setCompanyInfo
        // companyList.map(company => {
        //     if (company.advertiser_no === advertiserNo) {
        //         setCompanyInfo(company);
        //     }
        // });

        const currAdsInfo = adsList.find(ads => ads.ads_no === adsNo);

        if (currAdsInfo === null) {
            return;
        }
        setAdsInfo(currAdsInfo);

        showAdsInfoForm();

        const adsNoInput = document.getElementById(
            'idx_ads_no',
        ) as HTMLInputElement;
        adsNoInput.value = currAdsInfo.ads_no;

        const title = document.getElementById('idx_title') as HTMLInputElement;
        if (title) {
            title.value = currAdsInfo.title;
            title.focus();
        }

        const content = document.getElementById(
            'idx_content',
        ) as HTMLInputElement;
        if (content) content.value = currAdsInfo.content;

        const adsLinkUrl = document.getElementById(
            'idx_ads_link_url',
        ) as HTMLInputElement;
        if (adsLinkUrl) adsLinkUrl.value = currAdsInfo.ads_link_url;

        const popupLinkUrl = document.getElementById(
            'idx_popup_link_url',
        ) as HTMLInputElement;
        if (popupLinkUrl) popupLinkUrl.value = currAdsInfo.popup_link_url;

        const popupCondition = document.getElementById(
            'idx_popup_condition',
        ) as HTMLInputElement;
        if (popupCondition) popupCondition.value = currAdsInfo.popup_condition;

        const startDate = document.getElementById(
            'idx_start_date',
        ) as HTMLInputElement;
        if (startDate) startDate.value = currAdsInfo.start_date;

        const endDate = document.getElementById(
            'idx_end_date',
        ) as HTMLInputElement;
        if (endDate) endDate.value = currAdsInfo.end_date;

        const adsPosition = document.getElementById(
            'idx_ads_position',
        ) as HTMLInputElement;
        if (adsPosition) adsPosition.value = currAdsInfo.ads_position;

        const memo = document.getElementById('idx_memo') as HTMLInputElement;
        if (memo) memo.value = currAdsInfo.memo;

        const active = document.getElementById('active') as HTMLInputElement;
        if (active) active.checked = currAdsInfo.active === 'Y' ? true : false;

        const inactive = document.getElementById(
            'inactive',
        ) as HTMLInputElement;
        if (inactive)
            inactive.checked = currAdsInfo.active === 'N' ? true : false;
    };

    const submit = async () => {
        const adsNoInput = document.getElementById(
            'idx_ads_no',
        ) as HTMLInputElement;
        const adsNo = parseInt(adsNoInput.value) || 0;

        const advertiserNoInput = document.getElementById(
            'idx_advertiser_no',
        ) as HTMLInputElement;
        const advertiserNo = parseInt(advertiserNoInput.value) || 0;

        const title = document.getElementById('idx_title') as HTMLInputElement;
        const content = document.getElementById(
            'idx_content',
        ) as HTMLInputElement;
        const adsLinkUrl = document.getElementById(
            'idx_ads_link_url',
        ) as HTMLInputElement;
        const popupLinkUrl = document.getElementById(
            'idx_popup_link_url',
        ) as HTMLInputElement;
        const popupCondition = document.getElementById(
            'idx_popup_condition',
        ) as HTMLInputElement;
        const startDate = document.getElementById(
            'idx_start_date',
        ) as HTMLInputElement;
        const endDate = document.getElementById(
            'idx_end_date',
        ) as HTMLInputElement;
        const adsPosition = document.getElementById(
            'idx_ads_position',
        ) as HTMLInputElement;
        const memo = document.getElementById('idx_memo') as HTMLInputElement;
        const active = document.getElementById('active') as HTMLInputElement;

        const data = {
            adsNo: adsNo,
            advertiserNo: advertiserNo,
            title: title.value,
            content: content.value,
            adsLinkUrl: adsLinkUrl.value,
            popupLinkUrl: popupLinkUrl.value,
            popupCondition: popupCondition.value,
            startDate: startDate.value,
            endDate: endDate.value,
            adsPosition: adsPosition.value,
            memo: memo.value,
            active: active.checked ? 'Y' : 'N',
        };

        // 빈 값 체크
        if (data.advertiserNo === 0) {
            alert('광고주를 선택해주세요.');
            advertiserNoInput.focus();
            return;
        }

        if (!data.title) {
            alert('제목을 입력해주세요.');
            title.focus();
            return;
        }
        if (!data.content) {
            alert('내용을 입력해주세요.');
            content.focus();
            return;
        }
        if (!data.adsLinkUrl) {
            alert('기본링크를 입력해주세요.');
            adsLinkUrl.focus();
            return;
        }
        if (!data.startDate) {
            alert('시작일을 입력해주세요.');
            startDate.focus();
            return;
        }
        if (!data.endDate) {
            alert('종료일을 입력해주세요.');
            endDate.focus();
            return;
        }
        if (!data.adsPosition) {
            alert('광고위치를 입력해주세요.');
            adsPosition.focus();
            return;
        }

        console.log(data);

        const result = await ApiService.setAds(data);
        if (result) {
            alert('저장되었습니다.');
            clearAdsInfoForm();
            getUserList();
        } else {
            alert('저장에 실패했습니다.');
            2;
        }
    };

    return (
        <div className="body-content">
            <table>
                <tr>
                    <td colSpan={4} className="left">
                        <div className="">
                            <button onClick={addAdsInfo}>광고 추가</button>
                        </div>
                    </td>
                    <td colSpan={4}>
                        <div className="">
                            전체 <b>{totalCnt}</b> 명
                        </div>
                    </td>
                </tr>
            </table>
            <table className="ads-list">
                <tr>
                    <th>NO</th>
                    <th>제목</th>
                    <th>광고주</th>
                    <th>내용</th>
                    <th>기본링크</th>
                    <th>팝업링크</th>
                    <th>팝업조건</th>
                    <th>시작시각</th>
                    <th>종료시각</th>
                    <th>광고위치</th>
                    <th>메모</th>
                    <th>활성화</th>
                    <th>등록일</th>
                    <th>수정일</th>
                </tr>
                {adsList.length &&
                    adsList.map(ads => {
                        return (
                            <tr
                                key={ads.ads_no}
                                onClick={e => loadAdsInfo(ads.ads_no, e)}
                            >
                                <td>{ads.ads_no}</td>
                                <td>{ads.title}</td>
                                <td>{ads.com_name}</td>
                                <td>{ads.content}</td>
                                <td>{ads.ads_link_url}</td>
                                <td>{ads.popup_link_url}</td>
                                <td>{ads.popup_condition}</td>
                                <td>{ads.start_date}</td>
                                <td>{ads.end_date}</td>

                                <td>{ads.ads_position}</td>
                                <td>{ads.memo}</td>
                                <td>{ads.active}</td>
                                <td>{ads.reg_date}</td>
                                <td>
                                    {ads.reg_date !== ads.mod_date
                                        ? ads.mod_date
                                        : '-'}
                                </td>
                            </tr>
                        );
                    })}
            </table>
            <table className="ads-list-bottom">
                <tr>
                    <td align="left">
                        <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button>
                    </td>
                    <td align="right">
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(1);
                            }}
                        >
                            처음 (1)
                        </div>
                        {currPage > 1 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(currPage - 1);
                                }}
                            >
                                이전
                            </div>
                        )}

                        <div className="page-nav-btn page-nav-curr">
                            {currPage}
                        </div>

                        {nextPage > 0 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(nextPage);
                                }}
                            >
                                다음
                            </div>
                        )}
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(Math.ceil(totalCnt / numPerPage));
                            }}
                        >
                            마지막 ({Math.ceil(totalCnt / numPerPage)})
                        </div>
                    </td>
                </tr>
            </table>

            <div className="ads-info-container">
                <table className="company-info-table">
                    <tr>
                        <td className="head">광고 번호 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="idx_ads_no"
                                className="input-no"
                                readOnly
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">광고주 : </td>
                        <td className="body">
                            <select
                                className="select-company"
                                id="idx_advertiser_no"
                            >
                                <option value="0">
                                    광고주를 선택해 주세요
                                </option>
                                <option value="0" disabled={true}>
                                    ---------------------
                                </option>
                                {companyList.length &&
                                    companyList.map(company => {
                                        return (
                                            <option
                                                key={company.advertiser_no}
                                                value={company.advertiser_no}
                                                selected={
                                                    adsInfo &&
                                                    adsInfo.advertiser_no ===
                                                        company.advertiser_no
                                                        ? true
                                                        : false
                                                }
                                            >
                                                {company.advertiser_no}.{' '}
                                                {company.com_name}
                                            </option>
                                        );
                                    })}
                            </select>
                        </td>
                    </tr>

                    <tr>
                        <td className="head">광고제목 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="idx_title"
                                className="input-text"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">광고 본문 : </td>
                        <td className="body">
                            <textarea
                                id="idx_content"
                                className="input-content"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">기본링크 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="idx_ads_link_url"
                                className="input-link_url"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">팝업링크 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="idx_popup_link_url"
                                className="input-link_url"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">팝업조건 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="idx_popup_condition"
                                className="input-condition"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">시작 : </td>
                        <td className="body">
                            <input
                                type="datetime-local"
                                className="input-date"
                                id="idx_start_date"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">종료 : </td>
                        <td className="body">
                            <input
                                type="datetime-local"
                                className="input-date"
                                id="idx_end_date"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="head">광고위치 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="idx_ads_position"
                                className="input-email"
                            />
                        </td>
                    </tr>

                    <tr>
                        <td className="head">비고 : </td>
                        <td className="body">
                            <textarea id="idx_memo" className="input-content" />
                        </td>
                    </tr>

                    <tr>
                        <td className="head">활성화 : </td>
                        <td className="body">
                            <input
                                type="radio"
                                id="active"
                                name="activeStatus"
                                className="input-radio"
                                defaultChecked
                            />
                            <label htmlFor="active">활성</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                                type="radio"
                                id="inactive"
                                name="activeStatus"
                                className="input-radio"
                            />
                            <label htmlFor="inactive">비활성</label>
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td className="center">
                            <button onClick={submit}>저장</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
});

/**
 *   /* 
  `advertiser_no` int(3) unsigned NOT NULL AUTO_INCREMENT,
  `com_name` varchar(50),
  `com_no` varchar(20),
  `corp_no` varchar(20),
  `com_ceo` varchar(20),
  `com_tel` varchar(20),
  `com_email` varchar(50),
  `com_addr1` varchar(100),
  `com_addr2` varchar(100),
  `uptae` varchar(100),
  `upjong` varchar(100),
  `active` char(1) NOT NULL DEFAULT 'N',
  `reg_date` datetime NOT NULL DEFAULT current_timestamp(),
  `mod_date` datetime NOT NULL DEFAULT current_timestamp(),
  `deleted` char(1) DEFAULT 'N',  

*/

export default AdsList;
