import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/server-schedule-job.scss';

const ServerScheduleJob = observer(() => {
    const { adminInfo } = store;

    useEffect(() => {}, []);

    const execSortHomeStoryList = async () => {
        const btnObj = document.getElementById(
            'idx_sort_home_story_list',
        ) as HTMLButtonElement;

        const orgButtonText = btnObj.innerText;

        btnObj.innerText = orgButtonText + ' -> 처리 중...';
        btnObj.disabled = true;
        btnObj.classList.add('active');

        setTimeout(async () => {
            const res = await ApiService.updateStoryListSort();

            btnObj.classList.remove('active');

            if (res) {
                btnObj.innerText = orgButtonText + ' -> 처리 성공';
                btnObj.classList.add('success');
            } else {
                btnObj.innerText = orgButtonText + ' -> 처리 실패';
                btnObj.classList.add('fail');
            }

            setTimeout(() => {
                btnObj.classList.remove('success');
                btnObj.classList.remove('fail');

                btnObj.disabled = false;
                btnObj.innerText = orgButtonText;
            }, 2000);
        }, 500);
    };

    const execUpdateExpiredStory = async () => {
        const btnObj = document.getElementById(
            'idx_update_expired_story',
        ) as HTMLButtonElement;

        const orgButtonText = btnObj.innerText;

        btnObj.innerText = orgButtonText + ' -> 처리 중...';
        btnObj.disabled = true;
        btnObj.classList.add('active');

        setTimeout(async () => {
            const res = await ApiService.updateExpiredStory();

            btnObj.classList.remove('active');

            if (res) {
                btnObj.innerText = orgButtonText + ' -> 처리 성공';
                btnObj.classList.add('success');
            } else {
                btnObj.innerText = orgButtonText + ' -> 처리 실패';
                btnObj.classList.add('fail');
            }

            setTimeout(() => {
                btnObj.classList.remove('success');
                btnObj.classList.remove('fail');

                btnObj.disabled = false;
                btnObj.innerText = orgButtonText;
            }, 2000);
        }, 500);
    };

    const execMoveDeletedStory = async () => {
        const btnObj = document.getElementById(
            'idx_move_story_to_trash',
        ) as HTMLButtonElement;

        const orgButtonText = btnObj.innerText;

        btnObj.innerText = orgButtonText + ' -> 처리 중...';
        btnObj.disabled = true;
        btnObj.classList.add('active');

        setTimeout(async () => {
            const res = await ApiService.moveDeletedStory();

            btnObj.classList.remove('active');

            if (res) {
                btnObj.innerText = orgButtonText + ' -> 처리 성공';
                btnObj.classList.add('success');
            } else {
                btnObj.innerText = orgButtonText + ' -> 처리 실패';
                btnObj.classList.add('fail');
            }

            setTimeout(() => {
                btnObj.classList.remove('success');
                btnObj.classList.remove('fail');

                btnObj.disabled = false;
                btnObj.innerText = orgButtonText;
            }, 2000);
        }, 500);
    };

    return (
        <div className="body-content">
            <div className="job-list">
                <button
                    className="btn btn-primary"
                    id="idx_sort_home_story_list"
                    onClick={execSortHomeStoryList}
                >
                    홈 Story 목록 순서 정렬 실행
                </button>
            </div>
            <div className="job-list">
                <button
                    className="btn btn-primary"
                    id="idx_update_expired_story"
                    onClick={execUpdateExpiredStory}
                >
                    삭제대상 Story 정리 실행
                </button>
            </div>
            <div className="job-list">
                <button
                    className="btn btn-primary"
                    id="idx_move_story_to_trash"
                    onClick={execMoveDeletedStory}
                >
                    삭제된 Story 휴지통으로 이동 실행
                </button>
            </div>
        </div>
    );
});

export default ServerScheduleJob;
