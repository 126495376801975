// export const getAuthData = () => {
//     return window.localStorage.getItem('authData') || '';
// };
// export const setAuthData = (authData: string) => {
//     window.localStorage.setItem('authData', authData);
// };
// export const getDeviceData = () => {
//     let deviceData = window.localStorage.getItem('deviceData') || '';
//     if (deviceData === '') {
//         deviceData = Date.now().toString(16);
//         // 랜덤하게 섞는다.
//         deviceData = deviceData
//             .split('')
//             .sort(function () {
//                 return 0.5 - Math.random();
//             })
//             .join('');

//         setDeviceData(deviceData);
//     }
//     return deviceData;
// };
// export const setDeviceData = (deviceData: string) => {
//     window.localStorage.setItem('deviceData', deviceData);
// };
// export const setUserId = (userId: string) => {
//     window.localStorage.setItem('userId', userId);
// };
// export const getUserId = () => {
//     return window.localStorage.getItem('userId') || '';
// };
// export const logout = () => {
//     window.localStorage.removeItem('authData');
//     window.localStorage.removeItem('userId');
//     window.localStorage.removeItem('deviceData');
// };

export const getAuthData = () => {
    return window.sessionStorage.getItem('authData') || '';
};
export const setAuthData = (authData: string) => {
    window.sessionStorage.setItem('authData', authData);
};
export const getDeviceData = () => {
    let deviceData = window.sessionStorage.getItem('deviceData') || '';
    if (deviceData === '') {
        deviceData = Date.now().toString(16);
        // 랜덤하게 섞는다.
        deviceData = deviceData
            .split('')
            .sort(function () {
                return 0.5 - Math.random();
            })
            .join('');

        setDeviceData(deviceData);
    }
    return deviceData;
};
export const setDeviceData = (deviceData: string) => {
    window.sessionStorage.setItem('deviceData', deviceData);
};
export const setUserId = (userId: string) => {
    window.sessionStorage.setItem('userId', userId);
};
export const getUserId = () => {
    return window.sessionStorage.getItem('userId') || '';
};
export const logout = () => {
    window.sessionStorage.removeItem('authData');
    window.sessionStorage.removeItem('userId');
    window.sessionStorage.removeItem('deviceData');
};
