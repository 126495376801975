//storyDetail
import { observer } from 'mobx-react';
import store from '../../store';
import { useEffect, useState } from 'react';
import * as ApiService from '../../api/ApiService';
import { stringify } from 'querystring';
import * as utils from '../../utils/utils';
import '../../res/scss/reply-list.scss';
import { Iuser, IusersPage } from '../../const/types';

type strContentType = {
    content: string;
    image: string;
};

const ReportReplyList = observer(() => {
    const { adminInfo } = store;

    const [replyList, setReplyList] = useState([]);
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = useState(setPerPageList[0]);

    useEffect(() => {
        getReportReplyList();
    }, []);

    const getReportReplyList = async (page = 1, perPage = numPerPage) => {
        const dataInfo = await ApiService
            .getReportReplyList
            //page,
            //perPage,
            ();

        setReplyList(dataInfo);
        // if (dataInfo.list.length > 0) {
        //     console.log(dataInfo.list);
        //     setReplyList(dataInfo.list);
        // }
        // setCurrPage(dataInfo.currentPage);
        // setTotalCnt(dataInfo.totalCnt);
        // setNextPage(dataInfo.nextPage);
    };

    const movePage = (page: number) => {
        getReportReplyList(page);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        getReportReplyList(1, perPage);
    };

    const goDirectPage = () => {
        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        const page = parseInt(idx.value);
        if (page > 0 && page <= Math.ceil(totalCnt / numPerPage)) {
            getReportReplyList(page);
        } else {
            alert(
                '잘못된 페이지 번호입니다.\n마지막 페이지는 ' +
                    Math.ceil(totalCnt / numPerPage) +
                    ' 페이지 입니다.',
            );
            idx.value = '1';
        }
    };

    const replyDelete = async (replyNo = 0, storyNo = 0) => {
        const formData = new FormData();

        // if (strContent.no == 0)
        //     return;

        if (window.confirm('진짜 삭제 하시겠습니까?')) {
            const res = await ApiService.deleteReply(replyNo, storyNo);
            console.log('삭제 완료', replyNo);
            if (res) {
                alert('삭제 완료');
                //getStoryList();
                //resetDetail();

                return true;
            }
        } else {
            alert('삭제 취소');
        }
    };

    return (
        <div className="body-content">
            <table className="user-list-top">
                <tr>
                    <td colSpan={8}>
                        <div className="">
                            전체 <b>{totalCnt}</b> 개
                        </div>
                    </td>
                </tr>
            </table>
            <table className="user-list">
                <tr>
                    <th style={{ width: '50px' }}>NO </th>
                    <th style={{ width: '300px' }}>신고 내용</th>
                    <th style={{ width: '300px' }}>댓글 내용</th>
                    <th style={{ width: '50px' }}>story no</th>
                    <th style={{ width: '100px' }}>신고등록일</th>
                    <th style={{ width: '100px' }}>신고자</th>
                    <th style={{ width: '50px' }}>삭제</th>
                </tr>
                {replyList.map((reply: any) => {
                    return (
                        <tr key={reply.no}>
                            <td>{reply.no}</td>
                            <td>{reply.content}</td>
                            <td>{reply.reply_content}</td>
                            <td>{reply.story_no}</td>
                            <td>{reply.reg_date}</td>
                            <td>{reply.user_name}</td>
                            <td>
                                <strong>
                                    <button
                                        onClick={e =>
                                            replyDelete(
                                                reply.reply_no,
                                                reply.story_no,
                                            )
                                        }
                                        style={{
                                            color: 'black',
                                            backgroundColor: 'skyblue',
                                            width: 100,
                                            height: 20,
                                            borderRadius: 10,
                                        }}
                                    >
                                        삭제
                                    </button>
                                </strong>
                            </td>
                        </tr>
                    );
                })}
            </table>
            <table className="user-list-bottom">
                <tr>
                    <td align="left">
                        <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button>
                    </td>
                    <td align="right">
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(1);
                            }}
                        >
                            처음 (1)
                        </div>
                        {currPage > 1 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(currPage - 1);
                                }}
                            >
                                이전
                            </div>
                        )}

                        <div className="page-nav-btn page-nav-curr">
                            {currPage}
                        </div>

                        {nextPage > 0 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(nextPage);
                                }}
                            >
                                다음
                            </div>
                        )}
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(Math.ceil(totalCnt / numPerPage));
                            }}
                        >
                            마지막 ({Math.ceil(totalCnt / numPerPage)})
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    );
});

export default ReportReplyList;
