import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect, useState } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/qna.scss';
import { Iuser, IusersPage } from '../../const/types';
import { set } from 'mobx';
import { send } from 'process';

type TqnaDetail = {
    qna_no: number;
    user_no: number;
    user_name: string;
    user_email: string;
    question: string;
    answer: string;
    reg_date: string;
    ans_date: string;
    deleted: string;
};

const Qna = observer(() => {
    const getDefaultDetail = (): TqnaDetail => {
        // let today = new Date();
        // const year = today.getFullYear();
        // const month = today.getMonth() + 1;
        // const date = today.getDate();
        // const hours = today.getHours();
        // let minutes = today.getMinutes();
        // const autoDateTime = `${year}-${month < 10 ? '0' + month : month}-${
        //     date < 10 ? '0' + date : date
        // } ${hours < 10 ? '0' + hours : hours}:${
        //     minutes < 10 ? '0' + minutes : minutes
        // }:00`;

        return {
            qna_no: 0,
            user_no: 0,
            user_name: '',
            user_email: '',
            question: '',
            answer: '',
            reg_date: '',
            ans_date: '',
            deleted: 'N',
        } as TqnaDetail;
    };

    const { adminInfo } = store;
    const [qnaList, setQnaList] = useState<TqnaDetail[]>([]);
    const [qnaDetail, setQnaDetail] = useState<TqnaDetail>(getDefaultDetail());
    const [totalCnt, setTotalCnt] = useState(0);
    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = useState(setPerPageList[0]);
    const [bPushNoti, setBPushNoti] = useState(false);

    useEffect(() => {
        getQnaList();
    }, []);

    const getQnaList = async (page = 1, perPage = numPerPage) => {
        const list = await ApiService.getQnaList(page, perPage);
        setQnaList(list.list);
        setTotalCnt(list.totalCnt);
        setCurrPage(list.currentPage);
        setNextPage(list.nextPage);
        setQnaDetail(getDefaultDetail());
    };

    const movePage = (page: number) => {
        getQnaList(page);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        getQnaList(1, perPage);
        setCurrPage(1);

        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        idx.value = '1';
    };

    const goDirectPage = () => {
        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        const page = parseInt(idx.value);
        if (page > 0 && page <= Math.ceil(totalCnt / numPerPage)) {
            getQnaList(page);
        } else {
            utils.showToastMessage(
                '잘못된 페이지 번호입니다.\n마지막 페이지는 ' +
                    Math.ceil(totalCnt / numPerPage) +
                    ' 페이지 입니다.',
                2,
            );
            idx.value = '1';
        }
    };

    const selectQna = (qnaNo: number) => {
        const qna = qnaList.find(qna => qna.qna_no === qnaNo);
        console.log(qna);
        if (!qna) {
            return;
        }
        setQnaDetail(qna);
        if (qna.answer === null || qna.answer === '') {
            setBPushNoti(true);
        } else {
            setBPushNoti(false);
        }
    };

    const resetDetailForm = () => {
        let today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        const hours = today.getHours();
        let minutes = today.getMinutes();
        const autoDateTime = `${year}-${month < 10 ? '0' + month : month}-${
            date < 10 ? '0' + date : date
        } ${hours < 10 ? '0' + hours : hours}:${
            minutes < 10 ? '0' + minutes : minutes
        }:00`;

        setQnaDetail(getDefaultDetail());
    };

    const writeQnaReply = async () => {
        console.log('saveNotice');

        const pushNotiChkObj = document.getElementById(
            'idx_push_ok',
        ) as HTMLInputElement;
        const pushNoti = pushNotiChkObj.checked ? 'Y' : 'N';

        const result = await ApiService.writeQnaReply({
            ...qnaDetail,
            push_noti: pushNoti,
        });
        if (result) {
            getQnaList();
            resetDetailForm();
        } else {
            utils.showToastMessage('저장에 실패했습니다.');
        }
    };

    const deleteQna = async () => {
        console.log('deleteQna');

        if (
            confirm(
                `선택된 문의글 (No. ${qnaDetail.qna_no})정말 삭제하시겠습니까?`,
            ) === false
        ) {
            return;
        }

        const result = await ApiService.deleteQna(qnaDetail.qna_no);
        if (result) {
            getQnaList();
            resetDetailForm();
        } else {
            utils.showToastMessage('삭제에 실패했습니다.');
        }
    };

    return (
        <div className="body-content">
            <table className="qna-list-top">
                <tr>
                    <td className="left">
                        <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button>
                    </td>
                    <td>총 {totalCnt}건 </td>
                </tr>
            </table>
            <table className="qna-list">
                <colgroup>
                    <col width="20" />
                    <col width="150" />
                    <col width="*" />
                    <col width="250" />
                    <col width="150" />
                    <col width="150" />
                </colgroup>

                <tr>
                    <th>NO</th>
                    <th>작성자</th>
                    <th>문의</th>
                    <th>답변</th>
                    <th>문의일자</th>
                    <th>답변일자</th>
                    <th>삭제</th>
                </tr>
                {qnaList.length &&
                    qnaList.map(qna => {
                        return (
                            <tr
                                key={qna.qna_no}
                                className={
                                    (qna.qna_no === qnaDetail.qna_no
                                        ? ' selected'
                                        : '') +
                                    (qna.answer === null || qna.answer === ''
                                        ? ' not-answered'
                                        : '') +
                                    (qna.deleted === 'Y' ? ' deleted' : '')
                                }
                                onClick={() => selectQna(qna.qna_no)}
                            >
                                <td>{qna.qna_no}</td>
                                <td className="name">{qna.user_name}</td>
                                <td
                                    className={
                                        'content' +
                                        (qna.deleted === 'Y' ? ' deleted' : '')
                                    }
                                >
                                    {qna.question || '-'}
                                </td>
                                <td className={'content'}>
                                    {qna.answer || '-'}
                                </td>
                                <td>{qna.reg_date}</td>
                                <td>{qna.ans_date || '-'}</td>
                                <td>{qna.deleted === 'Y' ? 'Y' : '-'}</td>
                            </tr>
                        );
                    })}
            </table>
            <table className="user-list-bottom">
                <tr>
                    <td className="left">
                        {/* <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button> */}
                    </td>
                    <td align="right">
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(1);
                            }}
                        >
                            처음 (1)
                        </div>
                        {currPage > 1 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(currPage - 1);
                                }}
                            >
                                이전
                            </div>
                        )}

                        <div className="page-nav-btn page-nav-curr">
                            {currPage}
                        </div>

                        {nextPage > 0 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(nextPage);
                                }}
                            >
                                다음
                            </div>
                        )}
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(Math.ceil(totalCnt / numPerPage));
                            }}
                        >
                            마지막 ({Math.ceil(totalCnt / numPerPage)})
                        </div>
                    </td>
                </tr>
            </table>

            <div
                className="qna-detail"
                style={{ display: qnaDetail.qna_no === 0 ? 'none' : '' }}
            >
                <div className="line title">상세 내용</div>
                <div className="line">
                    <div className="head">등록자</div>
                    <div className="body">
                        {`${qnaDetail.user_name} (${qnaDetail.user_email})`}
                    </div>
                </div>
                <div className="line">
                    <div className="head">번호</div>
                    <div className="body">
                        <pre className="number">{qnaDetail.qna_no}</pre>
                    </div>
                </div>
                <div className="line">
                    <div className="head">문의글</div>
                    <div className="body">
                        <pre>{qnaDetail.question}</pre>
                    </div>
                </div>
                <div className="line">
                    <div className="head">답변</div>
                    <div className="body">
                        {qnaDetail.deleted !== 'Y' ? (
                            <textarea
                                id="idx_notice_content"
                                className="content"
                                value={qnaDetail.answer || ''}
                                onChange={e => {
                                    setQnaDetail({
                                        ...qnaDetail,
                                        answer: e.target.value,
                                    });
                                }}
                            />
                        ) : (
                            <pre className="answer">{qnaDetail.answer}</pre>
                        )}
                    </div>
                </div>

                <div
                    className={
                        'line' + (qnaDetail.deleted === 'Y' ? ' hide' : '')
                    }
                >
                    <div className="head"></div>
                    <div className="body">
                        <input
                            type="checkbox"
                            id="idx_push_ok"
                            checked={bPushNoti}
                            onChange={e => {
                                setBPushNoti(e.target.checked);
                            }}
                        />{' '}
                        <label htmlFor="idx_push_ok">
                            답변등록 시 작성자에게 푸시알림 전송
                        </label>
                    </div>
                </div>

                <div
                    className={
                        'line' + (qnaDetail.deleted === 'Y' ? ' hide' : '')
                    }
                >
                    <div className="head"></div>
                    <div className="body">
                        <button onClick={writeQnaReply}>답변 등록하기</button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Qna;
