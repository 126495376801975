import $ from 'jquery';

const toastObjId = 'TOAST_MSG';

let toastTimer: any = null;
export const showToastMessage = (text: string, sec = 1, bFade = true) => {
    console.log('bFade : ', bFade);

    // sec = sec * 1000;

    let toastObj = document.getElementById(toastObjId);
    if (toastObj === null) {
        toastObj = document.createElement('div');
        toastObj.id = toastObjId;
        toastObj.className = 'toast-message active';
        // toastObj.innerHTML = '<p>' + text + '</p>';
        const textList = text.split('|');
        for (let i = 0; i < textList.length; i++) {
            const pElem = document.createElement('p');
            pElem.innerText = textList[i];
            toastObj.appendChild(pElem);
        }

        const btnClose = document.createElement('button');
        btnClose.type = 'button';
        btnClose.className = 'btn-close';
        btnClose.onclick = () => {
            hideToastMessage();
        };
        toastObj.appendChild(btnClose);

        document.body.appendChild(toastObj);

        if (sec > 0) {
            toastTimer = setTimeout(
                () => {
                    hideToastMessage();
                },
                sec * 1000 + 300,
            );
        }
        // if (bFade) {
        //     toastObj.style.display = 'none';
        //     $(toastObj).fadeIn('fast', 'swing', () => {
        //         toastTimer = setTimeout(() => {
        //             hideToastMessage();
        //         }, sec * 1000);
        //     });
        // } else {
        //     toastObj.style.display = 'block';
        //     toastTimer = setTimeout(() => {
        //         hideToastMessage();
        //     }, sec * 1000);
        // }
    } else {
        if (toastTimer) clearTimeout(toastTimer);
        toastTimer = null;

        $(toastObj).children('p').remove();

        const textList = text.split('|');
        for (let i = 0; i < textList.length; i++) {
            const pElem = document.createElement('p');
            pElem.innerText = textList[i];
            toastObj.appendChild(pElem);
        }
        $(toastObj).addClass('active');
        // if (bFade) {
        // toastObj.style.opacity = String(1);

        if (sec > 0) {
            toastTimer = setTimeout(
                () => {
                    hideToastMessage();
                },
                sec * 1000 + 300,
            );
        }
        // }
    }
};
export const hideToastMessage = () => {
    if (toastTimer) clearTimeout(toastTimer);
    toastTimer = null;
    if ($('.toast-message')) {
        // $('.toast-message').fadeOut('fast', 'swing', () => {
        if ($('.toast-message')) {
            // $('.toast-message').remove();
            $('.toast-message').removeClass('active');
            setTimeout(() => {
                $('.toast-message p').remove();
            }, 300);
        }
        // });
    }
};

let loadingTimer: any = null;
export const showLoading = (maxSec = 10, transparent = false) => {
    if (loadingTimer) clearTimeout(loadingTimer);
    loadingTimer = null;

    let loadingDim = document.querySelector('.loading-dim') as HTMLDivElement;
    if (loadingDim === null) {
        loadingDim = document.createElement('div');
        loadingDim.className = 'loading-dim';
        document.body.appendChild(loadingDim);
    }
    if (transparent) {
        loadingDim.className = 'loading-dim trans active';
    } else {
        loadingDim.className = 'loading-dim active';
    }
    // loadingDim.style.display = 'block';

    let loadingObj = document.querySelector('.loading') as HTMLDivElement;
    if (loadingObj === null) {
        loadingObj = document.createElement('div');
        loadingObj.className = 'loading';
        loadingDim.appendChild(loadingObj);
    }

    loadingTimer = setTimeout(() => {
        hideLoading();
    }, maxSec * 1000);
};
export const hideLoading = () => {
    if (loadingTimer) clearTimeout(loadingTimer);
    loadingTimer = null;
    const loadingDim = document.querySelector('.loading-dim') as HTMLDivElement;
    if (loadingDim && loadingDim.classList.contains('active')) {
        loadingDim.classList.remove('active');
        // loadingDim.style.display = '';
        // loadingDim.className = 'loading-dim';
    }
};

export const getYoutubeIdFromUrl = (url: string) => {
    const regex =
        /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm;
    if (!regex.test(url)) return '';
    const matchList = url.match(regex);
    if (matchList && matchList.length > 0) {
        const youtubeId = matchList[0].split('/').pop();
        return youtubeId;
    } else {
        return '';
    }
};

export const getValidUrlLink = (url: string) => {
    if (url === '') return '';
    // 앞에 http://, https:// 가 없으면 붙여준다.
    if (url.indexOf('http://') === -1 && url.indexOf('https://') === -1) {
        url = 'http://' + url;
    }
    const regExp =
        /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()!@:%_+.~#?&//=]*)/gi;

    const matchList = url.match(regExp);
    if (matchList && matchList.length > 0) {
        return matchList[0];
    } else {
        return '';
    }
};
export const getYoutubeThumbnail = (url: string) => {
    const youtubeId = getYoutubeIdFromUrl(url);
    console.log('youtubeId: ', youtubeId);
    if (youtubeId === '')
        return {
            id: '',
            url: '',
        };
    return {
        id: youtubeId,
        url: `https://img.youtube.com/vi/${youtubeId}/0.jpg`,
    };
};

export const showFileSize = (size: number) => {
    if (size < 1024) {
        return size + ' bytes';
    } else if (size < 1024 * 1024) {
        return Math.round(size / 1024) + ' KB';
    } else if (size < 1024 * 1024 * 1024) {
        return Math.round(size / 1024 / 1024) + ' MB';
    } else {
        return Math.round(size / 1024 / 1024 / 1024) + ' GB';
    }
};

export const pageNation = (currPage = 1, perPage = 3, totalCnt = 0) => {
    const totalPage = Math.ceil(totalCnt / perPage);
    const startPage = Math.floor((currPage - 1) / 10) * 10 + 1;
    const endPage = startPage + 9 > totalPage ? totalPage : startPage + 9;

    return {
        totalPage,
        startPage,
        endPage,
    };
};

export const numberFormat = (num: number) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const copyToClipboard = (text: string) => {
    window.navigator.clipboard.writeText(text);
    showToastMessage('주소가 복사되었습니다.', 0.5);
};

export const checkStoryId = (storyId: string) => {
    var regExp = new RegExp('^[13mn2][a-km-zA-HJ-NP-Z1-9]{25,34}$');
    if (regExp.test(storyId)) {
        return true;
    }
    return false;
};
