import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect, useState } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import '../../res/scss/new-story.scss';

const NewStory = observer(() => {
    const { adminInfo } = store;
    const [emoticons, setEmoticons] = useState<any[]>([]);
    const [adsInfoList, setAdsInfoList] = useState<any[]>([]);
    const [adsInfo, setAdsInfo] = useState<any>(null);

    useEffect(() => {
        console.log(adminInfo.getLeftMenu());
        addInputAutoDateTime();
        getEmoticons();

        setUseDeleteMsg(false);
    }, []);

    //https://youtube.com/shorts/XK0NyrqnHDk?si=DfQ1s3Mwrkxp2UHM

    const getEmoticons = async () => {
        const res = await ApiService.getEmoticonList();
        console.log('getEmoticons : ', res);
        setEmoticons(res);
    };

    const onPageConfirmYoutubeLink = () => {
        // 0.5 초후 실행
        setTimeout(() => {
            confirmYoutubeLink();
        }, 500);
    };
    const confirmYoutubeLink = () => {
        const youtubeLinkForm = document.getElementById(
            'youtube_link',
        ) as HTMLInputElement;
        const youtubeLink = youtubeLinkForm.value;
        console.log('youtubeLink: ', youtubeLink);

        const validLink = utils.getValidUrlLink(youtubeLink);
        if (validLink === '') {
            alert('유효한 링크가 아닙니다.');
            return;
        }

        const youtubeThumbnailInfo = utils.getYoutubeThumbnail(validLink);
        console.log('youtubeThumbnail: ', youtubeThumbnailInfo);

        const youtubeId = youtubeThumbnailInfo.id || '';
        const youtubeUrl = youtubeThumbnailInfo.url || '';

        if (youtubeId === '') {
            alert('유효한 유튜브 링크가 아닙니다.');
            return;
        }

        const youtubeIdForm = document.getElementById(
            'youtube_id',
        ) as HTMLInputElement;
        if (youtubeIdForm) {
            youtubeIdForm.value = youtubeId;
        }

        //youtube-thumbnail
        const imageObj = document.createElement('img');
        imageObj.src = youtubeUrl;
        imageObj.className = 'youtube-thumbnail';
        const youtubeThumbnailObj =
            document.getElementById('youtube-thumbnail');
        if (youtubeThumbnailObj) {
            youtubeThumbnailObj.innerHTML = '';
            youtubeThumbnailObj.appendChild(imageObj);

            const youtubeContainer = document.getElementById(
                'youtube-thumbnail-container',
            );
            if (youtubeContainer) {
                youtubeContainer.style.display = 'block';
            }
        }
    };
    const closeYoutubeThumbnail = () => {
        const youtubeContainer = document.getElementById(
            'youtube-thumbnail-container',
        );
        if (youtubeContainer) {
            youtubeContainer.style.display = 'none';
        }
        const youtubeLinkForm = document.getElementById(
            'youtube_link',
        ) as HTMLInputElement;
        if (youtubeLinkForm) youtubeLinkForm.value = '';

        const youtubeIdForm = document.getElementById(
            'youtube_id',
        ) as HTMLInputElement;
        if (youtubeIdForm) {
            youtubeIdForm.value = '';
        }
    };

    // 1 or -1
    const addInputAutoDateTime = (type = 1) => {
        //idx_datetime
        // 오늘보다 1개월 뒤 날짜 시간을 입력 id 가 idx_datetime 인 input 에 입력
        const idxDateTime = document.getElementById(
            'idx_datetime',
        ) as HTMLInputElement;

        let today;
        //idxDateTime 에 입력된 값이 없으면 오늘날짜 + addMonth 개월
        if (idxDateTime.value === '') {
            // 오늘날짜 + addMonth 개월
            today = new Date();
            today.setMonth(today.getMonth() + 1);
        } else {
            // 입력된 날짜가 있으면 그 날짜 + addMonth 개월
            const formDataValue = idxDateTime.value;
            today = new Date(formDataValue);
            today.setMonth(today.getMonth() + type);
        }
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        const date = today.getDate();
        const hours = today.getHours();
        let minutes = today.getMinutes();
        // 분을 10분 단위로 올림
        minutes = Math.floor(minutes / 10) * 10;

        const autoDateTime = `${year}-${month < 10 ? '0' + month : month}-${
            date < 10 ? '0' + date : date
        } ${hours < 10 ? '0' + hours : hours}:${
            minutes < 10 ? '0' + minutes : minutes
        }`;

        // autoDateTime 가 오늘보다 이전이면 알림
        const todayTime = new Date().getTime();
        const autoDateTimeTime = new Date(autoDateTime).getTime();
        if (autoDateTimeTime < todayTime) {
            alert('오늘보다 이전 날짜는 입력할 수 없습니다.');
            return;
        }

        if (idxDateTime) idxDateTime.value = autoDateTime;

        changeLimitConditionViewCheck();
    };

    const clearDateTime = () => {
        const idxDateTime = document.getElementById(
            'idx_datetime',
        ) as HTMLInputElement;

        if (idxDateTime) idxDateTime.value = '';

        changeLimitConditionViewCheck();
    };

    const submitPost = async () => {
        const submitBtn = document.getElementById(
            'idx_submit',
        ) as HTMLButtonElement;

        await generateAddrKey();

        const formData = new FormData();
        // 주소키
        const idxAddrKey = document.querySelector(
            '#idx_addr_key',
        ) as HTMLInputElement;
        if (idxAddrKey) {
            formData.append('storyAddrKey', idxAddrKey.value);
        }

        // 작성자 이메일
        const writerEmail = document.querySelector(
            '#writer_email',
        ) as HTMLInputElement;
        if (writerEmail) {
            formData.append('userEmail', writerEmail.value);
        }

        // 작성자 이름
        const writerName = document.querySelector(
            '#writer_name',
        ) as HTMLInputElement;
        if (writerName) {
            formData.append('userName', writerName.value);
        }

        // 키워드
        const keyword = document.querySelector('#keyword') as HTMLInputElement;
        if (keyword) {
            formData.append('keyword', keyword.value);
        }

        // 이미지
        const images = document.querySelector('#images') as HTMLInputElement;
        if (images && images.files && images.files.length > 0) {
            // formData.append('images', images.files);
            for (let i = 0; i < images.files.length; i++) {
                formData.append('images', images.files[i]);
                console.log('images.files: ', i, images.files[i]);
            }
        }

        // 오디오
        const audio = document.querySelector('#audio') as HTMLInputElement;
        if (audio && audio.files && audio.files.length > 0) {
            formData.append('audio', audio.files[0]);
        }

        // 유튜브 ID
        const youtubeId = document.querySelector(
            '#youtube_id',
        ) as HTMLInputElement;
        if (youtubeId) {
            formData.append('youtubeId', youtubeId.value);
        }

        // 제목
        formData.append('subject', '');

        // 내용
        const content = document.querySelector(
            '.input-textarea',
        ) as HTMLTextAreaElement;
        if (content) {
            formData.append('content', content.value);
        }

        // 글 공개 여부
        const idxView = document.querySelector('#idx_view') as HTMLInputElement;
        if (idxView) {
            formData.append('view', idxView.checked ? 'y' : 'n');
        }

        // 댓글 허용
        const idxReply = document.querySelector(
            '#idx_reply',
        ) as HTMLInputElement;
        if (idxReply) {
            formData.append('reply', idxReply.checked ? 'y' : 'n');
        }

        // 삭제 날짜
        const idxDateTime = document.querySelector(
            '#idx_datetime',
        ) as HTMLInputElement;
        if (idxDateTime) {
            formData.append('limitDateTime', idxDateTime.value);
        }

        // 삭제 조회수
        const idxViewCount = document.querySelector(
            '#idx_view_count',
        ) as HTMLInputElement;
        if (idxViewCount) {
            formData.append('limitViewCnt', idxViewCount.value);
        }

        // 삭제 날짜/조회수 결합조건
        if (idxDateTime.value === '' || idxViewCount.value === '0') {
            formData.append('limitCondition', '');
        } else {
            const idxConditionO = document.querySelector(
                '#idx_condition_o',
            ) as HTMLInputElement;
            const idxConditionA = document.querySelector(
                '#idx_condition_a',
            ) as HTMLInputElement;
            let limitCondition = 'o';
            if (idxConditionO && idxConditionA) {
                if (idxConditionA.checked) {
                    limitCondition = 'a';
                }
            }
            formData.append('limitCondition', limitCondition);
        }

        // 삭제 메세지 팝업 사용 여부
        const useDeleteMsgObj = document.querySelector(
            '#idx_use_delete_msg',
        ) as HTMLInputElement;

        let useDeleteMsg = 'n';
        let deleteMsgPoint = '';
        let deleteMsgContent = '';
        let deleteMsgEmoticonUrl = '';
        if (useDeleteMsgObj) {
            if (useDeleteMsgObj.checked) {
                useDeleteMsg = 'n';
            } else {
                useDeleteMsg = 'y';
            }
        }
        formData.append('useDeleteMsg', useDeleteMsg);

        if (useDeleteMsg === 'y') {
            // 삭제 메시지 위치
            const deleteMsgPointSObj = document.querySelector(
                '#idx_delete_msg_point_s',
            ) as HTMLInputElement;
            if (deleteMsgPointSObj && deleteMsgPointSObj.checked) {
                deleteMsgPoint = 's';
            } else {
                const deleteMsgPointEObj = document.querySelector(
                    '#idx_delete_msg_point_e',
                ) as HTMLInputElement;
                if (deleteMsgPointEObj && deleteMsgPointEObj.checked) {
                    deleteMsgPoint = 'e';
                } else {
                    deleteMsgPoint = 's';
                }
            }

            // 삭제 메시지 내용
            const deleteMsgContentObj = document.querySelector(
                'textarea[name="delete_msg_content"]',
            ) as HTMLTextAreaElement;
            if (deleteMsgContentObj) {
                deleteMsgContent = deleteMsgContentObj.value;
            }

            // 삭제 이미지
            const deleteMsgImageCloud = document.querySelector(
                '#idx_delete_msg_image_type_cloud',
            ) as HTMLInputElement;

            const deleteMsgImages = document.querySelector(
                '#deleteMsgImages',
            ) as HTMLInputElement;
            if (
                deleteMsgImages &&
                deleteMsgImages.files &&
                deleteMsgImages.files.length > 0
            ) {
                formData.append('deleteMsgImages', deleteMsgImages.files[0]);
            }

            // 삭제 이모티콘
            const deleteMsgEmoticonUrlObj = document.getElementById(
                'deleteMsgEmoticonUrl',
            ) as HTMLInputElement;
            if (deleteMsgEmoticonUrlObj) {
                deleteMsgEmoticonUrl = deleteMsgEmoticonUrlObj.value;
            }
        }
        formData.append('deleteMsgPoint', deleteMsgPoint);
        formData.append('deleteMsgContent', deleteMsgContent);
        formData.append('deleteMsgEmoticonUrl', deleteMsgEmoticonUrl);

        // 광고 설정
        const adsConnectCheckObj = document.querySelector(
            '#idx_connect_ads',
        ) as HTMLInputElement;
        if (adsConnectCheckObj && adsConnectCheckObj.checked) {
            if (adsInfo === null) {
                alert('광고를 선택해주세요.');
                return;
            }
            formData.append('adsNo', adsInfo.ads_no);

            // 광고 설정
            const adsPositionObj = document.querySelector(
                '#idx_ads_position',
            ) as HTMLInputElement;
            if (adsPositionObj) {
                if (adsPositionObj.value.trim() === '') {
                    alert(
                        '이 광고는 광고 위치가 설정되어 있지 않습니다. 광고 설정을 확인해주세요.',
                    );
                    return;
                }
                formData.append('adsPosition', adsPositionObj.value);
            }
        } else {
            formData.append('adsNo', '0');
            formData.append('adsPosition', '');
        }

        submitBtn.disabled = true;
        submitBtn.innerHTML = '등록 중...';

        const res = await ApiService.saveNewStory(formData);
        console.log('saveNewStory : ', res);

        if (res) {
            submitBtn.innerHTML = '등록 성공';
        } else {
            submitBtn.innerHTML = '등록 실패';
        }

        setTimeout(() => {
            // 내용 초기화
            if (content) content.value = '';
            // 키워드 초기화
            if (keyword) keyword.value = '';
            // 이미지 초기화
            if (images) images.value = '';
            // 오디오 초기화
            if (audio) audio.value = '';
            // 유튜브 초기화
            closeYoutubeThumbnail();

            submitBtn.disabled = false;
            submitBtn.innerHTML = '등록';
        }, 1000);

        /**
 *         "userEmail": userInfoController.userEmail.value,
        "userName": userInfoController.userName.value,
        "audio": isAudioSelected.value && audioFile.value != null
            ? await dio.MultipartFile.fromFile(audioFile.value!.path,
                filename: audioFile.value!.path.split('/').last)
            : '',
        "youtubeId": youtubeId.value,
        "subject": subject.value,
        "content": content.value,
        "limitDateTime": isCheckLimitDate.value ? limitDateTime.value : null,
        "view": isView.value ? 'y' : 'n',
        "reply": isReply.value ? 'y' : 'n',
        "limitViewCnt": isCheckLimitViewCnt.value ? limitViewCnt.value : 0,
        "limitCondition": isCheckLimitViewCnt.value && isCheckLimitDate.value
            ? limitCondition.value
            : null,
        "storyAddrKey": storyAddrKey,
 */
    };

    const generateAddrKey = async () => {
        const addrKey = await ApiService.getNewAddrKey();
        console.log(addrKey);

        const idxAddrKey = document.getElementById(
            'idx_addr_key',
        ) as HTMLInputElement;
        if (idxAddrKey) {
            idxAddrKey.value = addrKey.address;
        }
    };

    const makeKeyword = async () => {
        const content = document.querySelector(
            '.input-textarea',
        ) as HTMLTextAreaElement;
        if (content) {
            const keyword = document.querySelector(
                '#keyword',
            ) as HTMLInputElement;
            if (keyword && content.value.trim() !== '') {
                // keyword.value = utils.extractKeyword(content.value);
                const keywordList = await ApiService.makeKeyword(
                    content.value.trim(),
                );
                console.log('keywordList : ', keywordList);
                keyword.value = keywordList.join(', ');
            } else {
                alert('내용을 입력해주세요.');
                keyword.focus();
            }
        }
    };

    const changeLimitConditionViewCheck = () => {
        const limitViewCntObj = document.getElementById(
            'idx_view_count',
        ) as HTMLInputElement;
        const limitDateTimeObj = document.getElementById(
            'idx_datetime',
        ) as HTMLInputElement;
        const idxConditionLine = document.getElementById('idx_condition_line');

        if (limitViewCntObj && limitDateTimeObj && idxConditionLine) {
            if (
                limitViewCntObj.value === '0' ||
                limitDateTimeObj.value === ''
            ) {
                idxConditionLine.style.display = 'none';
            } else {
                idxConditionLine.style.display = '';
            }
        }
    };

    const closeImagePreview = () => {
        const previewImageContainer = document.querySelector(
            '.image-preview-container #image-preview',
        ) as HTMLDivElement;
        if (previewImageContainer) {
            previewImageContainer.innerHTML = '';
        }

        const imageContainer = document.getElementById(
            'image-preview-container',
        );
        if (imageContainer) {
            imageContainer.style.display = 'none';
        }
        const imagesForm = document.getElementById(
            'images',
        ) as HTMLInputElement;
        //imagesForm 초기화
        if (imagesForm) imagesForm.value = '';
    };

    const setImagePreview = (event: any) => {
        const imageContainer = document.getElementById(
            'image-preview-container',
        );
        if (imageContainer === null) return;
        imageContainer.style.display = 'block';

        const previewImageContainer = imageContainer.querySelector(
            '#image-preview',
        ) as HTMLDivElement;
        if (previewImageContainer === null) return;

        previewImageContainer.style.display = '';
        previewImageContainer.innerHTML = '';

        let totalFileSize = 0;
        for (const image of event.target.files) {
            const reader = new FileReader();
            totalFileSize += image.size;

            reader.onload = function (e) {
                const img = document.createElement('img');
                const tmpImg = document.createElement('img');
                if (e.target) {
                    const imageBox = document.createElement('div');
                    imageBox.className = 'image-box';

                    const imageSrc = e.target.result as string;

                    img.setAttribute('src', imageSrc);
                    imageBox.appendChild(img);

                    const textObj = document.createElement('div');
                    textObj.className = 'text';
                    // textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p>`;
                    // textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p><p>${imgWidth} x  ${imgHeight}</p>`;

                    if (totalFileSize > 20 * 1024 * 1024) {
                        textObj.classList.add('red');
                    }

                    imageBox.appendChild(textObj);
                    previewImageContainer.appendChild(imageBox);

                    // 임시 공간에도 넣는다.
                    const tmpObj = document.querySelector(
                        '.image-preview-tmp',
                    ) as HTMLDivElement;
                    tmpImg.setAttribute('src', imageSrc);
                    tmpObj.appendChild(tmpImg);

                    tmpImg.onload = function () {
                        // 이미지 사이즈 넣기
                        // 이미지 가로 세로 길이 정보
                        const imgWidth = utils.numberFormat(tmpImg.width);
                        const imgHeight = utils.numberFormat(tmpImg.height);
                        textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p><p>${imgWidth} x  ${imgHeight}</p>`;
                    };

                    // textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p><p>${imgWidth} x  ${imgHeight}</p>`;
                }
            };
            console.log(image);
            reader.readAsDataURL(image);
        }

        // totalFileSize 가 10MB 이상이면 알림
        if (totalFileSize > 10 * 1024 * 1024) {
            alert('첨부 파일의 총 용량은 10MB 이하여야 합니다.');

            const textObjList = document.querySelectorAll(
                'div#image-preview-container .text',
            ) as NodeListOf<HTMLDivElement>;
            for (let i = 0; i < textObjList.length; i++) {
                textObjList[i].classList.add('red');
            }
        }
    };

    const showAdsConfig = (event: React.ChangeEvent<HTMLInputElement>) => {
        console.log(event.target.checked);

        const adsConfig = document.querySelector('.ads-config');
        if (!adsConfig) return;

        if (event.target.checked) {
            adsConfig.classList.add('active');
            getAdsInfoList();
        } else {
            adsConfig.classList.remove('active');
            setAdsInfo(null);
            setAdsInfoList([]);
        }
    };

    const getAdsInfoList = async () => {
        const res = await ApiService.getAdsInfoList();
        console.log('getAdsInfoList : ', res);
        setAdsInfoList(res.list);
    };

    const selectAds = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const adsNo = Number(event.target.value);
        if (adsNo === 0) {
            setAdsInfo(null);
            return;
        }
        const adsInfo = adsInfoList.find(item => item.ads_no === adsNo);
        console.log('selectAds : ', adsInfo);
        setAdsInfo(adsInfo);
    };

    const selectItem = (no: number) => {
        console.log('selectItem : ', no);

        const itemContainer = document.querySelector('.delete-msg-cloud-image');
        if (!itemContainer) return;
        const itemList = itemContainer.querySelectorAll('.item');
        itemList.forEach(item => {
            const itemNo = Number(item.getAttribute('emoticon-no'));
            if (itemNo === no) {
                item.classList.add('selected');
            } else {
                item.classList.remove('selected');
            }
        });

        //deleteMsgEmoticonUrl
        const deleteMsgEmoticonUrl = document.getElementById(
            'deleteMsgEmoticonUrl',
        ) as HTMLInputElement;

        const selectedEmoticon = emoticons.find(item => item.no === no);
        if (selectedEmoticon) {
            deleteMsgEmoticonUrl.value = selectedEmoticon.emoticon_file;
        }
    };

    const setDeleteImagePreview = (event: any) => {
        const imageContainer = document.getElementById(
            'delete-image-preview-container',
        );
        if (imageContainer === null) return;
        imageContainer.style.display = 'block';

        const previewImageContainer = imageContainer.querySelector(
            '#image-preview',
        ) as HTMLDivElement;
        if (previewImageContainer === null) return;

        previewImageContainer.style.display = '';
        previewImageContainer.innerHTML = '';

        let totalFileSize = 0;
        for (const image of event.target.files) {
            const reader = new FileReader();
            totalFileSize += image.size;

            reader.onload = function (e) {
                const img = document.createElement('img');
                const tmpImg = document.createElement('img');
                if (e.target) {
                    const imageBox = document.createElement('div');
                    imageBox.className = 'image-box';

                    const imageSrc = e.target.result as string;

                    img.setAttribute('src', imageSrc);
                    imageBox.appendChild(img);

                    const textObj = document.createElement('div');
                    textObj.className = 'text';
                    // textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p>`;
                    // textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p><p>${imgWidth} x  ${imgHeight}</p>`;

                    if (totalFileSize > 10 * 1024 * 1024) {
                        textObj.classList.add('red');
                    }

                    imageBox.appendChild(textObj);
                    previewImageContainer.appendChild(imageBox);

                    // 임시 공간에도 넣는다.
                    const tmpObj = document.querySelector(
                        '.image-preview-tmp',
                    ) as HTMLDivElement;
                    tmpImg.setAttribute('src', imageSrc);
                    tmpObj.appendChild(tmpImg);

                    tmpImg.onload = function () {
                        // 이미지 사이즈 넣기
                        // 이미지 가로 세로 길이 정보
                        const imgWidth = utils.numberFormat(tmpImg.width);
                        const imgHeight = utils.numberFormat(tmpImg.height);
                        textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p><p>${imgWidth} x  ${imgHeight}</p>`;
                    };

                    // textObj.innerHTML = `<p>${image.name}</p><p>${utils.showFileSize(image.size)}</p><p>${imgWidth} x  ${imgHeight}</p>`;
                }
            };
            console.log(image);
            reader.readAsDataURL(image);
        }

        // totalFileSize 가 10MB 이상이면 알림
        if (totalFileSize > 10 * 1024 * 1024) {
            alert('첨부 파일의 총 용량은 10MB 이하여야 합니다.');

            const textObjList = document.querySelectorAll(
                'div#image-preview-container .text',
            ) as NodeListOf<HTMLDivElement>;
            for (let i = 0; i < textObjList.length; i++) {
                textObjList[i].classList.add('red');
            }
        }
    };
    const closeDeleteImagePreview = () => {
        const previewImageContainer = document.querySelector(
            '.image-preview-container #image-preview',
        ) as HTMLDivElement;
        if (previewImageContainer) {
            previewImageContainer.innerHTML = '';
        }

        const imageContainer = document.getElementById(
            'delete-image-preview-container',
        );
        if (imageContainer) {
            imageContainer.style.display = 'none';
        }
        const imagesForm = document.getElementById(
            'deleteMsgImages',
        ) as HTMLInputElement;
        //imagesForm 초기화
        if (imagesForm) imagesForm.value = '';
    };

    const setUseDeleteMsg = (bUse = true) => {
        //idx_use_delete_msg
        const useDeleteMsgObj = document.getElementById(
            'idx_use_delete_msg',
        ) as HTMLInputElement;
        if (useDeleteMsgObj) {
            // if (bUse) {
            //     useDeleteMsgObj.checked = true;
            // } else {
            //     useDeleteMsgObj.checked = false;
            // }

            const popupSettingStartObj = document.getElementById(
                'idx_popup_setting_start',
            );
            if (popupSettingStartObj) {
                // popupSettingStartObj 의 sibling 을 찾아서 display 를 변경한다.
                let sibling =
                    popupSettingStartObj.nextElementSibling as HTMLDivElement;
                while (
                    sibling &&
                    sibling.id !== 'delete-image-preview-container'
                ) {
                    sibling.style.display = bUse ? '' : 'none';
                    sibling = sibling.nextElementSibling as HTMLDivElement;
                }
            }
        }
    };

    const checkUseDeleteMsgSetting = () => {
        const useDeleteMsgObj = document.getElementById(
            'idx_use_delete_msg',
        ) as HTMLInputElement;

        if (useDeleteMsgObj && useDeleteMsgObj.checked) {
            setUseDeleteMsg(false);
        } else {
            setUseDeleteMsg(true);
            checkUseDeleteImageSetting();
        }
    };

    const checkUseDeleteImageSetting = () => {
        // idx_delete_msg_image_type_cloud
        // delete-msg-cloud-image

        const deleteMsgImageCloud = document.querySelector(
            '#idx_delete_msg_image_type_cloud',
        ) as HTMLInputElement;
        const deleteMsgCloudImage = document.querySelector(
            '.delete-msg-cloud-image',
        ) as HTMLDivElement;
        if (deleteMsgImageCloud && deleteMsgImageCloud.checked) {
            deleteMsgCloudImage.style.display = '';
        } else {
            deleteMsgCloudImage.style.display = 'none';
        }

        const deleteMsgImageUpload = document.querySelector(
            '#idx_delete_msg_image_type_upload',
        ) as HTMLInputElement;
        const deleteMsgUploadImage = document.querySelector(
            '.delete-msg-upload-image',
        ) as HTMLDivElement;
        if (deleteMsgImageUpload && deleteMsgImageUpload.checked) {
            deleteMsgUploadImage.style.display = '';
        } else {
            deleteMsgUploadImage.style.display = 'none';
        }
    };

    return (
        <div className="body-content">
            <div className="reg-form">
                <div className="form-line" style={{ display: 'none' }}>
                    <div className="form-label">글 주소</div>
                    <div className="form-input">
                        <input
                            type="text"
                            className="input-addrkey"
                            id="idx_addr_key"
                            readOnly={true}
                        />
                        <button
                            className="btn btn-blue"
                            onClick={generateAddrKey}
                        >
                            새 주소 생성
                        </button>
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">작성자 이름</div>
                    <div className="form-input">
                        <input
                            type="text"
                            className="input-name"
                            id="writer_name"
                        />
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">작성자 이메일</div>
                    <div className="form-input">
                        <input
                            type="text"
                            className="input-email"
                            id="writer_email"
                            defaultValue={'dev.coreple@gmail.com'}
                        />
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">내용</div>
                    <div className="form-input">
                        <textarea rows={7} className="input-textarea" />
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">키워드</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="text"
                                className="input-keyword"
                                id="keyword"
                            />
                            <button
                                className="btn btn-blue"
                                onClick={makeKeyword}
                            >
                                자동 키워드 추출
                            </button>
                        </div>
                        <div>
                            <p>
                                콤마로 구문하여 나열합니다. (공백으로 두면
                                서버에서 자동으로 등록하며, 입력하면 입력된
                                내용만 등록됩니다.)
                            </p>
                        </div>
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">이미지</div>
                    <div className="form-input">
                        <input
                            type="file"
                            id="images"
                            className="input-file"
                            multiple={true}
                            accept="image/*"
                            onChange={setImagePreview}
                        />
                    </div>
                </div>
                <div
                    className="form-line image-preview"
                    id="image-preview-container"
                >
                    <div id="image-preview"></div>
                    <div className="close" onClick={closeImagePreview}></div>
                    <div className="image-preview-tmp"></div>
                </div>

                <div className="form-line">
                    <div className="form-label">오디오</div>
                    <div className="form-input">
                        <input
                            type="file"
                            id="audio"
                            className="input-file"
                            multiple={false}
                            accept="audio/*"
                        />
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">유튜브링크</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="text"
                                className="input-youtube-link"
                                id="youtube_link"
                                onPaste={onPageConfirmYoutubeLink}
                            />
                            <input
                                type="text"
                                className="input-youtube-id"
                                id="youtube_id"
                                readOnly={true}
                                placeholder="Youtube ID"
                            />
                            <button
                                className="btn btn-blue"
                                onClick={confirmYoutubeLink}
                            >
                                링크 확인
                            </button>
                        </div>
                    </div>
                </div>
                <div
                    className="form-line youtube-thumb"
                    id="youtube-thumbnail-container"
                >
                    <div id="youtube-thumbnail"></div>
                    <div
                        className="close"
                        onClick={closeYoutubeThumbnail}
                    ></div>
                </div>

                <div className="form-line">
                    <div className="form-label">삭제 날짜</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="datetime-local"
                                className="input-date"
                                id="idx_datetime"
                                onChange={changeLimitConditionViewCheck}
                            />
                            <button
                                className="btn auto-input blue"
                                onClick={() => addInputAutoDateTime(1)}
                            >
                                + 1개월
                            </button>

                            <button
                                className="btn auto-input red"
                                onClick={() => addInputAutoDateTime(-1)}
                            >
                                - 1개월
                            </button>

                            <button
                                className="btn reset-input"
                                onClick={clearDateTime}
                            >
                                날짜 비우기
                            </button>
                        </div>
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">삭제 조회수</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="number"
                                className="input-number"
                                id="idx_view_count"
                                min="0"
                                defaultValue={0}
                                onChange={changeLimitConditionViewCheck}
                            />
                        </div>
                    </div>
                </div>

                <div
                    className="form-line"
                    id="idx_condition_line"
                    style={{ display: 'none' }}
                >
                    <div className="form-label">삭제 날짜/조회수 결합조건</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="radio"
                                id="idx_condition_o"
                                name="idx_condition"
                                value="o"
                                defaultChecked={true}
                            />
                            <label htmlFor="idx_condition_o">OR </label>
                            &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                id="idx_condition_a"
                                name="idx_condition"
                                value="a"
                            />
                            <label htmlFor="idx_condition_a">AND</label>
                        </div>
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">삭제 팝업</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="radio"
                                className="input-checkbox"
                                id="idx_use_delete_msg"
                                name="delete_msg_point"
                                value="n"
                                defaultChecked={true}
                                onChange={checkUseDeleteMsgSetting}
                            />
                            <label htmlFor="idx_use_delete_msg">
                                팝업 사용 안함
                            </label>
                            &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                className="input-checkbox"
                                id="idx_delete_msg_point_s"
                                value="s"
                                name="delete_msg_point"
                                defaultChecked={false}
                                onChange={checkUseDeleteMsgSetting}
                            />
                            <label htmlFor="idx_delete_msg_point_s">
                                진입시 사용
                            </label>
                            &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                className="input-checkbox"
                                id="idx_delete_msg_point_e"
                                value="e"
                                name="delete_msg_point"
                                defaultChecked={false}
                                onChange={checkUseDeleteMsgSetting}
                            />
                            <label htmlFor="idx_delete_msg_point_e">
                                이탈시 사용
                            </label>
                        </div>
                        <div id="idx_popup_setting_start"></div>
                        <div>팝업 내용 :</div>
                        <div>
                            <textarea
                                className="input-textarea"
                                name="delete_msg_content"
                            />
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            팝업 이미지 :
                            <input
                                type="radio"
                                className="input-checkbox"
                                id="idx_delete_msg_image_type_cloud"
                                name="delete_msg_image_type"
                                defaultChecked={true}
                                onChange={checkUseDeleteImageSetting}
                            />
                            <label htmlFor="idx_delete_msg_image_type_cloud">
                                이모티콘
                            </label>
                            &nbsp; &nbsp; &nbsp;
                            <input
                                type="radio"
                                className="input-checkbox"
                                id="idx_delete_msg_image_type_upload"
                                name="delete_msg_image_type"
                                defaultChecked={false}
                                onChange={checkUseDeleteImageSetting}
                            />
                            <label htmlFor="idx_delete_msg_image_type_upload">
                                이미지(업로드)
                            </label>
                        </div>
                        <div className="delete-msg-cloud-image">
                            {emoticons.length &&
                                emoticons.map(item => {
                                    return (
                                        <div
                                            key={item.no}
                                            className="item"
                                            {...{ 'emoticon-no': item.no }}
                                        >
                                            <Player
                                                autoplay
                                                loop
                                                src={item.emoticon_file}
                                                style={{
                                                    height: '100px',
                                                    width: '100px',
                                                }}
                                            >
                                                <Controls
                                                    visible={false}
                                                    buttons={['play', 'repeat']}
                                                ></Controls>
                                            </Player>

                                            <div
                                                className="click-area"
                                                onClick={() =>
                                                    selectItem(item.no)
                                                }
                                            >
                                                dd
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="delete-msg-upload-image">
                            <input type="hidden" id="deleteMsgEmoticonUrl" />
                            <input
                                type="file"
                                id="deleteMsgImages"
                                className="input-file"
                                multiple={false}
                                accept="image/*"
                                onChange={setDeleteImagePreview}
                            />
                        </div>
                        <div
                            className="form-line delete-image-preview"
                            id="delete-image-preview-container"
                            style={{ display: 'none' }}
                        >
                            <div id="image-preview"></div>
                            <div
                                className="close"
                                onClick={closeDeleteImagePreview}
                            ></div>
                            <div className="image-preview-tmp"></div>
                        </div>
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">글 공개 여부</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="checkbox"
                                className="input-checkbox"
                                id="idx_view"
                                defaultChecked={true}
                            />{' '}
                            <label htmlFor="idx_view">글 공개</label>
                        </div>
                    </div>
                </div>

                <div className="form-line">
                    <div className="form-label">댓글 허용</div>
                    <div className="form-input">
                        <div>
                            <input
                                type="checkbox"
                                className="input-checkbox"
                                id="idx_reply"
                                defaultChecked={true}
                            />{' '}
                            <label htmlFor="idx_reply">댓글 허용</label>
                        </div>
                    </div>
                </div>
            </div>

            <div className="reg-form" style={{ marginTop: '10px' }}>
                <div className="form-line" style={{ backgroundColor: '#fff' }}>
                    <div className="form-label">
                        <input
                            type="checkbox"
                            id="idx_connect_ads"
                            onChange={showAdsConfig}
                        />
                        <label htmlFor="idx_connect_ads">
                            {' '}
                            &nbsp; 광고 연결
                        </label>
                    </div>
                    <div className="form-input"></div>
                </div>
            </div>

            <div className="reg-form ads-config">
                <div className="form-line">
                    <div className="form-label">광고 선택</div>
                    <div className="form-input">
                        <select
                            className="select-adsinfo"
                            onChange={e => selectAds(e)}
                            id="idx_ads_no"
                        >
                            <option value="0">광고를 선택해 주세요</option>
                            <option value="0" disabled={true}>
                                --------------------
                            </option>
                            {adsInfoList.length &&
                                adsInfoList.map((item, index) => {
                                    return (
                                        <option
                                            key={item.ads_no}
                                            value={item.ads_no}
                                            disabled={item.active !== 'Y'}
                                        >
                                            {item.ads_no}. {item.title}{' '}
                                            {item.active !== 'Y' && '(비활성)'}
                                        </option>
                                    );
                                })}
                        </select>
                    </div>
                </div>
                <div className="form-line">
                    <div className="form-label">광고 위치</div>
                    <div className="form-input">
                        <input
                            type="text"
                            className="input-keyword"
                            id="idx_ads_position"
                            value={adsInfo ? adsInfo.ads_position : ''}
                            readOnly={true}
                        />
                        <p>
                            광고 위치 안내
                            <br />
                            (ex1. 홈 3번째 위치 : H3)
                            <br />
                            (ex2. 스토리 : S)
                            <br />
                            (ex3. 다음스토리 7번째 : N7)
                            <br />
                            (ex4. 홈 2번째, 스토리, 다음스토리 2번째 : H2,S,N2)
                        </p>
                    </div>
                </div>
            </div>

            <div className="form-submit">
                <button
                    id="idx_submit"
                    className="btn btn-blue"
                    onClick={submitPost}
                >
                    등록
                </button>
            </div>
        </div>
    );
});

export default NewStory;
