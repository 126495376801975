import { observer } from 'mobx-react';
import store from '../store';
import * as ApiService from '../api/ApiService';
import { useEffect, useState } from 'react';
import LeftMenu from './left';
import BodyContent from './body';
import LoginPage from './body/LoginPage';

const MainLayout = observer(() => {
    const { adminInfo } = store;

    const [loginStatus, setLoginStatus] = useState(0);

    useEffect(() => {
        console.log(adminInfo.getName());
        checkLogin();
    }, []);

    const checkLogin = async () => {
        const authData = await ApiService.checkLogin();
        if (authData !== null) {
            setLoginStatus(1);
        } else {
            setLoginStatus(-1);
        }
    };

    if (loginStatus === 0)
        return (
            <div className="loading">
                <div className="loading-icon"></div>
            </div>
        );

    return (
        <div className="main-container">
            {loginStatus === 1 ? (
                <>
                    <div className="main-head">TOSOMEBODY Admin System</div>
                    <div className="main-body">
                        <div className="main-body-left">
                            <LeftMenu />
                        </div>
                        <div className="main-body-middle">
                            <BodyContent />
                        </div>
                    </div>
                    <div className="main-foot">foot</div>
                </>
            ) : (
                <LoginPage />
            )}
        </div>
    );
});

export default MainLayout;
