import { observer } from 'mobx-react';
import store from '../../store';
import React, { useEffect } from 'react';
import * as utils from '../../utils/utils';
import * as ApiService from '../../api/ApiService';
import '../../res/scss/advertisers-list.scss';
import { Iuser, IusersPage } from '../../const/types';
import { set } from 'mobx';

const Advertisers = observer(() => {
    const { adminInfo } = store;

    const [companyList, setCompanyList] = React.useState([] as any[]);
    const [companyInfo, setCompanyInfo] = React.useState(null as any);
    const [currPage, setCurrPage] = React.useState(1);
    const [nextPage, setNextPage] = React.useState(1);
    const [totalCnt, setTotalCnt] = React.useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = React.useState(setPerPageList[0]);

    useEffect(() => {
        getUserList();
    }, []);

    const getUserList = async (page = 1, perPage = numPerPage) => {
        const dataInfo: IusersPage = await ApiService.getAdvertisersList(
            page,
            perPage,
        );
        if (dataInfo && dataInfo.list) {
            if (dataInfo.list.length > 0) {
                console.log(dataInfo.list);
                setCompanyList(dataInfo.list);
            }
            setCurrPage(dataInfo.currentPage);
            setTotalCnt(dataInfo.totalCnt);
            setNextPage(dataInfo.nextPage);
        }
    };

    const movePage = (page: number) => {
        getUserList(page);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        getUserList(1, perPage);
    };

    const goDirectPage = () => {
        const idx = document.getElementById('idx_go_page') as HTMLInputElement;
        const page = parseInt(idx.value);
        if (page > 0 && page <= Math.ceil(totalCnt / numPerPage)) {
            getUserList(page);
        } else {
            alert(
                '잘못된 페이지 번호입니다.\n마지막 페이지는 ' +
                    Math.ceil(totalCnt / numPerPage) +
                    ' 페이지 입니다.',
            );
            idx.value = '1';
        }
    };

    const showCompanyInfoForm = () => {
        console.log('showCompanyInfoForm');
        const companyInfoContainer = document.querySelector(
            '.company-info-container',
        ) as HTMLElement;
        if (companyInfoContainer) {
            companyInfoContainer.style.display = 'block';
        }
    };

    const clearCompanyInfoForm = () => {
        const advertiserNoInput = document.getElementById(
            'advertiser_no',
        ) as HTMLInputElement;
        advertiserNoInput.value = '0';

        const comName = document.getElementById('com_name') as HTMLInputElement;
        comName.value = '';

        const comNo = document.getElementById('com_no') as HTMLInputElement;
        comNo.value = '';

        const corpNo = document.getElementById('corp_no') as HTMLInputElement;
        corpNo.value = '';

        const comCeo = document.getElementById('com_ceo') as HTMLInputElement;
        comCeo.value = '';

        const comTel = document.getElementById('com_tel') as HTMLInputElement;
        comTel.value = '';

        const comEmail = document.getElementById(
            'com_email',
        ) as HTMLInputElement;
        comEmail.value = '';

        const comAddr1 = document.getElementById(
            'com_addr1',
        ) as HTMLInputElement;
        comAddr1.value = '';

        const comAddr2 = document.getElementById(
            'com_addr2',
        ) as HTMLInputElement;
        comAddr2.value = '';

        const uptae = document.getElementById('uptae') as HTMLInputElement;
        uptae.value = '';

        const upjong = document.getElementById('upjong') as HTMLInputElement;
        upjong.value = '';

        const active = document.getElementById('active') as HTMLInputElement;
        active.checked = true;
    };

    const addCompanyInfo = async () => {
        console.log('addCompany');

        showCompanyInfoForm();
        clearCompanyInfoForm();

        const comName = document.getElementById('com_name') as HTMLInputElement;

        comName.focus();
    };

    const loadCompanyInfo = async (advertiserNo: number, event: any) => {
        console.log('loadCompanyInfo');

        // setCompanyInfo
        // companyList.map(company => {
        //     if (company.advertiser_no === advertiserNo) {
        //         setCompanyInfo(company);
        //     }
        // });

        const currCompanyInfo = companyList.find(
            company => company.advertiser_no === advertiserNo,
        );

        if (currCompanyInfo === null) {
            return;
        }

        setCompanyInfo(currCompanyInfo);

        showCompanyInfoForm();

        const advertiserNoInput = document.getElementById(
            'advertiser_no',
        ) as HTMLInputElement;
        advertiserNoInput.value = currCompanyInfo.advertiser_no;

        const comName = document.getElementById('com_name') as HTMLInputElement;
        comName.value = currCompanyInfo.com_name;

        const comNo = document.getElementById('com_no') as HTMLInputElement;
        comNo.value = currCompanyInfo.com_no;

        const corpNo = document.getElementById('corp_no') as HTMLInputElement;
        corpNo.value = currCompanyInfo.corp_no;

        const comCeo = document.getElementById('com_ceo') as HTMLInputElement;
        comCeo.value = currCompanyInfo.com_ceo;

        const comTel = document.getElementById('com_tel') as HTMLInputElement;
        comTel.value = currCompanyInfo.com_tel;

        const comEmail = document.getElementById(
            'com_email',
        ) as HTMLInputElement;
        comEmail.value = currCompanyInfo.com_email;

        const comAddr1 = document.getElementById(
            'com_addr1',
        ) as HTMLInputElement;
        comAddr1.value = currCompanyInfo.com_addr1;

        const comAddr2 = document.getElementById(
            'com_addr2',
        ) as HTMLInputElement;
        comAddr2.value = currCompanyInfo.com_addr2;

        const uptae = document.getElementById('uptae') as HTMLInputElement;
        uptae.value = currCompanyInfo.uptae;

        const upjong = document.getElementById('upjong') as HTMLInputElement;
        upjong.value = currCompanyInfo.upjong;

        const active = document.getElementById('active') as HTMLInputElement;
        active.checked = currCompanyInfo.active === 'Y' ? true : false;

        const inactive = document.getElementById(
            'inactive',
        ) as HTMLInputElement;
        inactive.checked = currCompanyInfo.active === 'N' ? true : false;

        comName.focus();
    };

    const submit = async () => {
        const advertiserNoInput = document.getElementById(
            'advertiser_no',
        ) as HTMLInputElement;
        const advertiserNo = parseInt(advertiserNoInput.value) || 0;

        const comName = document.getElementById('com_name') as HTMLInputElement;
        const comNo = document.getElementById('com_no') as HTMLInputElement;
        const corpNo = document.getElementById('corp_no') as HTMLInputElement;
        const comCeo = document.getElementById('com_ceo') as HTMLInputElement;
        const comTel = document.getElementById('com_tel') as HTMLInputElement;
        const comEmail = document.getElementById(
            'com_email',
        ) as HTMLInputElement;
        const comAddr1 = document.getElementById(
            'com_addr1',
        ) as HTMLInputElement;
        const comAddr2 = document.getElementById(
            'com_addr2',
        ) as HTMLInputElement;
        const uptae = document.getElementById('uptae') as HTMLInputElement;
        const upjong = document.getElementById('upjong') as HTMLInputElement;
        const active = document.getElementById('active') as HTMLInputElement;

        const data = {
            advertiserNo: advertiserNo,
            comName: comName.value,
            comNo: comNo.value,
            corpNo: corpNo.value,
            comCeo: comCeo.value,
            comTel: comTel.value,
            comEmail: comEmail.value,
            comAddr1: comAddr1.value,
            comAddr2: comAddr2.value,
            uptae: uptae.value,
            upjong: upjong.value,
            active: active.checked ? 'Y' : 'N',
        };

        // 빈 값 체크
        if (!data.comName) {
            alert('회사명을 입력해주세요.');
            comName.focus();
            return;
        }
        if (!data.comNo) {
            alert('사업자번호를 입력해주세요.');
            comNo.focus();
            return;
        }
        if (!data.corpNo) {
            alert('법인번호를 입력해주세요.');
            corpNo.focus();
            return;
        }
        if (!data.comCeo) {
            alert('대표자명을 입력해주세요.');
            comCeo.focus();
            return;
        }
        if (!data.comTel) {
            alert('대표전화를 입력해주세요.');
            comTel.focus();
            return;
        }
        if (!data.comEmail) {
            alert('이메일을 입력해주세요.');
            comEmail.focus();
            return;
        }
        if (!data.comAddr1) {
            alert('주소1을 입력해주세요.');
            comAddr1.focus();
            return;
        }
        if (!data.comAddr2) {
            alert('주소2를 입력해주세요.');
            comAddr2.focus();
            return;
        }
        if (!data.uptae) {
            alert('업태를 입력해주세요.');
            uptae.focus();
            return;
        }
        if (!data.upjong) {
            alert('업종을 입력해주세요.');
            upjong.focus();
            return;
        }

        console.log(data);

        const result = await ApiService.setAdvertiser(data);
        if (result) {
            alert('저장되었습니다.');
            clearCompanyInfoForm();
            getUserList();
        } else {
            alert('저장에 실패했습니다.');
            2;
        }
    };

    return (
        <div className="body-content">
            <table>
                <tr>
                    <td colSpan={4} className="left">
                        <div className="">
                            <button onClick={addCompanyInfo}>
                                광고주 추가
                            </button>
                        </div>
                    </td>
                    <td colSpan={4}>
                        <div className="">
                            전체 <b>{totalCnt}</b> 명
                        </div>
                    </td>
                </tr>
            </table>
            <table className="company-list">
                <tr>
                    <th>NO</th>
                    <th>회사명</th>
                    <th>사업자번호</th>
                    <th>대표자</th>
                    <th>대표전화</th>
                    <th>주소</th>
                    <th>업태</th>
                    <th>업종</th>
                    <th>활성</th>
                    <th>등록일</th>
                    <th>수정일</th>
                </tr>
                {companyList.length &&
                    companyList.map(company => {
                        return (
                            <tr
                                key={company.advertiser_no}
                                onClick={e =>
                                    loadCompanyInfo(company.advertiser_no, e)
                                }
                            >
                                <td>{company.advertiser_no}</td>
                                <td>{company.com_name}</td>
                                <td>{company.com_no}</td>
                                <td>{company.com_ceo}</td>
                                <td>{company.com_tel}</td>
                                <td>
                                    <p>{company.com_addr1}</p>
                                    <p>{company.com_addr2}</p>
                                </td>
                                <td>{company.uptae}</td>
                                <td>{company.upjong}</td>
                                <td>{company.active}</td>
                                <td>{company.reg_date}</td>
                                <td>
                                    {company.reg_date !== company.mod_date
                                        ? company.mod_date
                                        : '-'}
                                </td>
                            </tr>
                        );
                    })}
            </table>
            <table className="company-list-bottom">
                <tr>
                    <td align="left">
                        <select onChange={setPerPage} value={numPerPage}>
                            {setPerPageList.map((num, idx) => {
                                return (
                                    <option key={idx} value={num}>
                                        {num}
                                    </option>
                                );
                            })}
                        </select>
                        개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <input
                            type="number"
                            id="idx_go_page"
                            defaultValue={1}
                        />{' '}
                        페이지로
                        <button onClick={goDirectPage}>이동하기</button>
                    </td>
                    <td align="right">
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(1);
                            }}
                        >
                            처음 (1)
                        </div>
                        {currPage > 1 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(currPage - 1);
                                }}
                            >
                                이전
                            </div>
                        )}

                        <div className="page-nav-btn page-nav-curr">
                            {currPage}
                        </div>

                        {nextPage > 0 && (
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(nextPage);
                                }}
                            >
                                다음
                            </div>
                        )}
                        <div
                            className="page-nav-btn"
                            onClick={() => {
                                movePage(Math.ceil(totalCnt / numPerPage));
                            }}
                        >
                            마지막 ({Math.ceil(totalCnt / numPerPage)})
                        </div>
                    </td>
                </tr>
            </table>

            <div className="company-info-container">
                <table className="company-info-table">
                    <tr>
                        <td className="head">광고주 번호 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="advertiser_no"
                                className="input-no"
                                readOnly
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">회사명 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_name"
                                className="input-company"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">사업자번호 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_no"
                                className="input-company"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">법인번호 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="corp_no"
                                className="input-company"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">대표자명 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_ceo"
                                className="input-ceo"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">대표전화 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_tel"
                                className="input-email"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">이메일 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_email"
                                className="input-email"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">주소1 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_addr1"
                                className="input-company"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">주소2 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="com_addr2"
                                className="input-company"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">업태 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="uptae"
                                className="input-text"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">업종 : </td>
                        <td className="body">
                            <input
                                type="text"
                                id="upjong"
                                className="input-text"
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className="head">활성화 : </td>
                        <td className="body">
                            <input
                                type="radio"
                                id="active"
                                name="activeStatus"
                                className="input-radio"
                                defaultChecked
                            />
                            <label htmlFor="active">활성</label>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                                type="radio"
                                id="inactive"
                                name="activeStatus"
                                className="input-radio"
                            />
                            <label htmlFor="inactive">비활성</label>
                        </td>
                    </tr>
                </table>
                <table>
                    <tr>
                        <td className="center">
                            <button onClick={submit}>저장</button>
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    );
});

/**
 *   /* 
  `advertiser_no` int(3) unsigned NOT NULL AUTO_INCREMENT,
  `com_name` varchar(50),
  `com_no` varchar(20),
  `corp_no` varchar(20),
  `com_ceo` varchar(20),
  `com_tel` varchar(20),
  `com_email` varchar(50),
  `com_addr1` varchar(100),
  `com_addr2` varchar(100),
  `uptae` varchar(100),
  `upjong` varchar(100),
  `active` char(1) NOT NULL DEFAULT 'N',
  `reg_date` datetime NOT NULL DEFAULT current_timestamp(),
  `mod_date` datetime NOT NULL DEFAULT current_timestamp(),
  `deleted` char(1) DEFAULT 'N',  

*/

export default Advertisers;
