//storyDetail
import { observer } from 'mobx-react';
import store from '../../store';
import { useEffect, useState } from 'react';
import * as ApiService from '../../api/ApiService';
import { stringify } from 'querystring';
import * as utils from '../../utils/utils';
import { OuterExpressionKinds } from 'typescript';
import { all } from 'axios';
import ReactPlayer from 'react-player';
import { set } from 'mobx';

type strContentType = {
    content: string;
    image: string;
};

// let orderbyField = 'viewOrder';
const StoryDetail = observer(() => {
    const { adminInfo } = store;

    const [storyList, setStoryList] = useState([]);
    const [strContent, setContent] = useState({
        no: 0,
        content: '',
        user_name: '',
        limit_view_cnt: '',
        limit_date: '',
        view_cnt: '',
        block_cnt: 0,
        user_email: '',
    });
    const [strKeyword, setKeyword] = useState({
        keyword1: '',
        keyword2: '',
        keyword3: '',
        keyword4: '',
        keyword5: '',
    });
    const [strAllKeyword, setAllKeyword] = useState('');

    //const [strKeyword, setKeyword] = useState<string[]>([]);
    const [imgFile, setImgFile] = useState<any[]>([]);
    const [setCheck, setCheckd] = useState({ isChecked: false });
    //const imgRef = useRef<HTMLInputElement>(null);

    const [currPage, setCurrPage] = useState(1);
    const [nextPage, setNextPage] = useState(1);
    const [totalCnt, setTotalCnt] = useState(0);
    const setPerPageList = [10, 20, 30, 40, 50, 100];
    const [numPerPage, setNumPerPage] = useState(setPerPageList[0]);

    const [reportList, setReportList] = useState([]);
    const [reportDetail, setReportDetail] = useState([]);
    const [youtubeId, setYoutubeId] = useState('');
    const [audioUrl, setAudioUrl] = useState('');
    const [orderbyField, setOrderbyField] = useState('regDateOrder');
    const [replyList, setReplyList] = useState<any[]>([]);
    const [addrKey, setAddrKey] = useState('');

    const movePage = (page: number = 0) => {
        const pageObj = document.querySelector(
            '#idx_go_page',
        ) as HTMLInputElement;

        let pageNo = page;
        if (pageNo == 0) {
            pageNo = Number(pageObj.value);
        } else {
            pageObj.value = pageNo.toString();
        }

        // const pageNo = Number(pageObj.value);
        getStoryList(pageNo);
    };

    const changePageValue = (e: any) => {
        const pageVal = Number(e.target.value);
        setCurrPage(pageVal);
        movePage(pageVal);
    };

    const setPerPage = (e: any) => {
        const perPage = e.target.value;
        setNumPerPage(perPage);
        setCurrPage(1);
        getStoryList(1, 'regDateOrder', perPage);
    };

    useEffect(() => {
        getStoryList();
        //setCheckd(false);
    }, []);

    const handlelimitdateChange = (e: any) => {
        setContent({
            ...strContent,
            limit_date: e.target.value,
        });
    };

    const handlelimitviewdateChange = (e: any) => {
        setContent({
            ...strContent,
            limit_view_cnt: e.target.value,
        });
    };

    const handleviewCntChange = (e: any) => {
        setContent({
            ...strContent,
            view_cnt: e.target.value,
        });
    };
    const handleblockCntChange = (e: any) => {
        setContent({
            ...strContent,
            block_cnt: e.target.value,
        });
    };

    const handleKeyword1Change = (e: any) => {
        setKeyword({ ...strKeyword, keyword1: e.target.value });
    };

    const handleKeyword2Change = (e: any) => {
        setKeyword({ ...strKeyword, keyword2: e.target.value });
    };

    const handleKeyword3Change = (e: any) => {
        setKeyword({ ...strKeyword, keyword3: e.target.value });
    };

    const handleKeyword4Change = (e: any) => {
        setKeyword({ ...strKeyword, keyword4: e.target.value });
    };

    const handleKeyword5Change = (e: any) => {
        setKeyword({ ...strKeyword, keyword5: e.target.value });
    };
    const handleAllKeywordChange = (e: any) => {
        let val = e.target.value;

        // , 기호 제거
        val = val.replace(/,/g, '');

        setAllKeyword(val);
    };

    const checkHandled = (checked: any) => {
        if (checked) {
            console.log('check');
            getReportList();
        } else {
            console.log('uncheck');
            getStoryList();
        }
    };

    const handleViewOrderCheckd = (e: any) => {
        console.log('handleViewOrderCheckd');
        setOrderbyField('viewOrder');
        getStoryList(1, 'viewOrder');
    };

    const handleViewCntCheckd = (e: any) => {
        console.log('handleViewCntCheckd');
        setOrderbyField('viewCnt');
        getStoryList(1, 'viewCnt');
    };

    const handleRegDateOrderCheckd = (e: any) => {
        console.log('handleRegDateOrderCheckd');
        setOrderbyField('regDateOrder');
        getStoryList(1, 'regDateOrder');
    };

    // const getStoryList = async (pageNo = 1) => {
    //     const res = await ApiService.getStoryList(pageNo);
    //     console.log(res);
    //     if (res) {
    //         setStoryList(res.list);
    //         setKeyword({ keyword1: '', keyword2: '', keyword3: '', keyword4: '', keyword5: '' });
    //     }

    //     setCurrPage(res.currentPage);
    //     setTotalCnt(res.totalCnt);
    //     setNextPage(res.nextPage);
    // };

    const getStoryList = async (pageNo = 1, orderType = '', perPage = 0) => {
        const formData = new FormData();

        if (perPage == 0) perPage = numPerPage;
        if (orderType == '') orderType = orderbyField;

        formData.append('page', pageNo.toString());
        // formData.append('orderType', orderType);
        formData.append('orderType', orderType);
        console.log('getStoryList', pageNo, orderType);

        formData.append('perPage', perPage.toString());

        //formData.append('regDate', regdate.value);

        const res = await ApiService.getStoryList(formData);
        console.log(res);
        if (res) {
            setStoryList(res.list);
            setKeyword({
                keyword1: '',
                keyword2: '',
                keyword3: '',
                keyword4: '',
                keyword5: '',
            });
        }

        setCurrPage(res.currentPage);
        setTotalCnt(res.totalCnt);
        setNextPage(res.nextPage);
    };

    const getReportList = async (pageNo = 1) => {
        const res = await ApiService.getStoryReportList();
        console.log(res);
        if (res) {
            setStoryList(res);
            setKeyword({
                keyword1: '',
                keyword2: '',
                keyword3: '',
                keyword4: '',
                keyword5: '',
            });
        }

        // setCurrPage(res.currentPage);
        // setTotalCnt(res.totalCnt);
        // setNextPage(res.nextPage);
    };

    const getReportDetail = async (
        event: React.MouseEvent<HTMLButtonElement>,
        storyNo = 1,
    ) => {
        const res = await ApiService.getStoryReportDetail(storyNo);
        console.log(res);
        if (res) {
            setReportDetail(res);
            //setKeyword({ keyword1: '', keyword2: '', keyword3: '', keyword4: '', keyword5: '' });
        }

        if (event.stopPropagation) {
            event.stopPropagation();
        } else {
            event.cancelable = true;
        }
        // setCurrPage(res.currentPage);
        // setTotalCnt(res.totalCnt);
        // setNextPage(res.nextPage);
    };

    const searchStroy = async () => {
        const formData = new FormData();

        const username = document.querySelector(
            '#user_name',
        ) as HTMLInputElement;
        if (username.value) {
            formData.append('userName', username.value);
        }

        const regdate = document.querySelector(
            '#story_regdate',
        ) as HTMLInputElement;
        if (regdate.value) {
            formData.append('regDate', regdate.value);
        }

        const keyword = document.querySelector(
            '#story_keyword',
        ) as HTMLInputElement;
        if (keyword.value) {
            formData.append('keyWord', keyword.value);
        }

        if (
            username.value == '' &&
            regdate.value == '' &&
            keyword.value == ''
        ) {
            getStoryList();
            resetDetail();
            return;
        }

        console.log(
            'searchStory :',
            username.value,
            regdate.value,
            keyword.value,
        );
        console.log(formData.append);

        const res = await ApiService.getSearchStory(formData);
        console.log(res);
        if (res) {
            setStoryList(res);
            resetDetail();
        }
    };

    const modifyStory = async (storyNo = 0) => {
        const formData = new FormData();
        let strkeyword: string[] = [];
        let keywordData: string = '';

        if (strContent.no == 0) return;

        formData.append('storyNo', storyNo.toString());

        const content = document.querySelector(
            '#s_content',
        ) as HTMLInputElement;
        if (content.value) {
            formData.append('content', content.value);
        }

        const username = document.querySelector(
            '#s_username',
        ) as HTMLInputElement;
        if (username.value) {
            formData.append('userName', username.value);
        }

        const regdate = document.querySelector(
            '#s_regdate',
        ) as HTMLInputElement;
        if (regdate.value) {
            formData.append('limit_date', regdate.value);
            console.log('s_regdate', regdate.value);
        } else formData.append('limit_date', '');

        const limit = document.querySelector('#s_limit') as HTMLInputElement;
        if (limit.value) {
            formData.append('limit_view_cnt', limit.value);
        } else formData.append('limit_view_cnt', '');

        const view_cnt = document.querySelector(
            '#s_view_cnt',
        ) as HTMLInputElement;
        if (view_cnt.value) {
            formData.append('view_cnt', view_cnt.value);
        } else formData.append('view_cnt', '');

        const block_cnt = document.querySelector(
            '#s_block_cnt',
        ) as HTMLInputElement;
        if (block_cnt.value) {
            formData.append('block_cnt', block_cnt.value);
        } else formData.append('block_cnt', '0');

        // const keyword1 = document.querySelector(
        //     '#s_keyword1',
        // ) as HTMLInputElement;
        // if (keyword1.value) {
        //     //formData.append('keyWord1', keyword1.value);
        //     strkeyword.push(keyword1.value);
        // }

        // const keyword2 = document.querySelector(
        //     '#s_keyword2',
        // ) as HTMLInputElement;
        // if (keyword2.value) {
        //     //formData.append('keyword2', keyword2.value);
        //     strkeyword.push(keyword2.value);
        // }

        // const keyword3 = document.querySelector(
        //     '#s_keyword3',
        // ) as HTMLInputElement;
        // if (keyword3.value) {
        //     //formData.append('keyWord3', keyword3.value);
        //     strkeyword.push(keyword3.value);
        // }

        // const keyword4 = document.querySelector(
        //     '#s_keyword4',
        // ) as HTMLInputElement;
        // if (keyword4.value) {
        //     //formData.append('keyWord4', keyword4.value);
        //     strkeyword.push(keyword4.value);
        // }

        // const keyword5 = document.querySelector(
        //     '#s_keyword5',
        // ) as HTMLInputElement;
        // if (keyword5.value) {
        //     //formData.append('keyWord5', keyword5.value);
        //     strkeyword.push(keyword5.value);
        // }

        // keywordData = strkeyword.join(',');
        const allKeywordObj = document.querySelector(
            '#s_all_keyword',
        ) as HTMLInputElement;
        let allKeywordData = allKeywordObj.value;
        // 줄바꿈을 구분자로 배열
        let allKeywordDataList = allKeywordData.split('\n');
        // 각 배열값의 좌우 공백 제거하고, 콤마 제거하고,  빈 값은 제거
        allKeywordDataList = allKeywordDataList
            .map(data => data.trim().replace(/,/g, ''))
            .filter(data => data != '');
        // 중복 제거
        allKeywordDataList = allKeywordDataList.filter(
            (item, index) => allKeywordDataList.indexOf(item) === index,
        );
        // ',' 로 구분하여 문자열로 변환
        keywordData = allKeywordDataList.join(',');
        console.log('allKeywordDataList:', allKeywordDataList);
        console.log('keywordData:', keywordData);

        formData.append('strkeyword', keywordData);

        console.log(
            'modify: ',
            storyNo,
            username.value,
            regdate.value,
            limit.value,
            view_cnt.value,
            keywordData,
        );

        const res = await ApiService.updateStory(formData);
        console.log(res);
        if (res) {
            // getStoryList();
            // resetDetail();
            utils.showToastMessage('수정 완료', 1);
        }
    };

    const getStoryDetail = async (storyID = 1, addrKey: string) => {
        resetDetail();
        setReplyList([]);
        const res = await ApiService.getDetail(storyID);
        console.log('detail:', res);
        if (res.length > 0) {
            setContent(res[0]);
            const keywordList: string[] = [];
            for (const data of res) {
                keywordList.push(data.keyword);
            }
            setKeyword({
                keyword1: keywordList[0] || '',
                keyword2: keywordList[1] || '',
                keyword3: keywordList[2] || '',
                keyword4: keywordList[3] || '',
                keyword5: keywordList[4] || '',
            });
            setAllKeyword(keywordList.join('\n'));
            setReportDetail([]);
            previewImage(res[0].image_url_list);
            setYoutubeId(res[0].youtube);
            setAudioUrl(res[0].audio_url);
            // setImgFile(res[0].image_url_list);

            if (res[0].reply_cnt > 0) {
                getReplyList(storyID);
            }

            setAddrKey(addrKey);
        }
    };

    const resetDetail = async () => {
        setContent({
            no: 0,
            content: '',
            user_name: '',
            limit_view_cnt: '',
            limit_date: '',
            view_cnt: '',
            block_cnt: 0,
            user_email: '',
        });
    };

    const deleteStory = async (storyNo = 0) => {
        const formData = new FormData();

        if (strContent.no == 0) return;

        if (window.confirm('진짜 삭제 하시겠습니까?')) {
            const res = await ApiService.deleteStory(storyNo);
            console.log('삭제 완료', storyNo);
            if (res) {
                alert('삭제 완료');
                getStoryList();
                resetDetail();

                return true;
            }
        } else {
            alert('삭제 취소');
        }
    };

    const banStory = async (storyNo = 0) => {
        const formData = new FormData();

        if (strContent.no == 0) return;

        if (window.confirm('진짜 비공개처리 하시겠습니까?')) {
            const res = await ApiService.banStory(storyNo);
            console.log('비공개처리 완료', storyNo);
            if (res) {
                alert('비공개처리 완료');
                getStoryList();
                resetDetail();

                return true;
            }
        } else {
            alert('비공개처리 취소');
        }
    };

    const previewImage = (imgList: string[]) => {
        if (imgList.length > 0) {
            let newList = [];
            for (let i = 0; i < imgList.length; i++) {
                newList.push(imgList[i] + '.t.webp');
            }
            setImgFile(newList);
        } else {
            setImgFile([]);
        }

        // let strimg = [];
        // strimg = (img || '').split(',');
        // if (img != '' && strimg.length > 0) {
        //     setImgFile(strimg);
        // } else {
        //     setImgFile([]);
        // }
    };

    const loadImageEvent = (e: any) => {
        // 이미지 원본 사이즈 가져오기
        const img = e.target;
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        console.log('width:', width, 'height:', height);

        const imgElem = e.target as HTMLImageElement;
        const nextElem = imgElem?.nextElementSibling as HTMLElement;
        if (nextElem) {
            let heightStr = String(width);
            let widthStr = String(height);

            // 콤마 적용
            heightStr = height.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            widthStr = width.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

            const imgSize = `${widthStr} x ${heightStr}`;
            nextElem.innerHTML = imgSize;
        }
    };

    const showType = (storyInfo: any) => {
        let typeStr = '';

        if (storyInfo.youtube_id !== '') {
            typeStr += 'Y';
        }
        if (storyInfo.image_url !== '') {
            typeStr += 'I';
        }
        if (storyInfo.title !== '') {
            typeStr += 'T';
        }
        if (storyInfo.audio_url !== '') {
            typeStr += 'A';
        }
        if (typeStr === '') {
            typeStr = '-';
        }
        return typeStr;
    };

    const showNumberFormat = (num: number) => {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    const showSimpleDate = (date: string) => {
        // return date.substring(0, 16);
        return date;
    };

    const getReplyList = async (storyId: number) => {
        const list = await ApiService.getReplyList(storyId);
        console.log('replyList:', list);

        setReplyList(list);
    };

    return (
        <div className="body-content">
            <div className="search-body">
                <table className="serach_table_layout1">
                    <thead>
                        <tr>
                            <div className="search-label">
                                <th> user name</th>
                            </div>
                            <td>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="input-name"
                                        id="user_name"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <div className="search-label">
                                <th> 등록일</th>
                            </div>
                            <td>
                                <div className="form-input">
                                    <input
                                        type="datetime-local"
                                        className="input-regdate"
                                        id="story_regdate"
                                    />
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <div className="search-label">
                                <th> keyword</th>
                            </div>
                            <td>
                                <div className="form-input">
                                    <input
                                        type="text"
                                        className="input-keyword"
                                        id="story_keyword"
                                    />
                                </div>
                            </td>
                        </tr>
                    </thead>
                </table>

                <div className="search-submit">
                    <button
                        id="idx_search"
                        className="btn btn-search"
                        onClick={() => searchStroy()}
                    >
                        조회
                    </button>
                </div>
            </div>

            <div className="search-condition-form">
                <div className="check_form">
                    <label>
                        <input
                            id="story_bad"
                            type="checkbox"
                            onChange={e => checkHandled(e.target.checked)}
                        />
                        &nbsp; 신고 된 스토리
                    </label>
                </div>

                <div className="radio_form">
                    <label>
                        &nbsp;&nbsp;
                        <input
                            type="radio"
                            name="order"
                            checked={orderbyField === 'viewOrder'}
                            onChange={handleViewOrderCheckd}
                        />
                        &nbsp;노출 순서 순
                    </label>

                    <label>
                        &nbsp;&nbsp;
                        <input
                            type="radio"
                            name="order"
                            checked={orderbyField === 'viewCnt'}
                            onChange={handleViewCntCheckd}
                        />
                        &nbsp; 조회수 순
                    </label>

                    <label>
                        &nbsp;&nbsp;
                        <input
                            type="radio"
                            name="order"
                            checked={orderbyField === 'regDateOrder'}
                            onChange={handleRegDateOrderCheckd}
                        />
                        &nbsp;등록일 순
                    </label>
                </div>
            </div>

            <table className="story-list" width={'100%'}>
                <colgroup>
                    <col width="70" />
                    <col width="*" />
                    <col width="100" />
                    <col width="60" />
                    <col width="60" />
                    <col width="60" />
                    <col width="60" />
                    <col width="130" />
                    <col width="60" />
                    <col width="130" />
                    <col width="60" />
                    <col width="60" />
                    <col width="60" />
                    <col width="60" />
                </colgroup>
                <tr>
                    <th rowSpan={2}>NO</th>
                    <th rowSpan={2}>CONTENT</th>
                    <th rowSpan={2}>작성자</th>
                    <th rowSpan={2}>타입</th>
                    <th rowSpan={2}>조회수</th>
                    <th rowSpan={2}>댓글수</th>
                    <th rowSpan={2}>
                        노출
                        <br />
                        순번
                    </th>
                    <th rowSpan={2}>등록일</th>
                    <th colSpan={2}>삭제설정</th>
                    <th rowSpan={2}>공개</th>
                    <th rowSpan={2}>차단</th>
                    <th rowSpan={2}>신고</th>
                    <th rowSpan={2}>웹링크</th>
                </tr>
                <tr>
                    <th>조회수</th>
                    <th>날짜</th>
                </tr>
                {storyList.map((story: any) => {
                    return (
                        <tr
                            onClick={() =>
                                getStoryDetail(story.no, story.addr_key)
                            }
                            key={story.no}
                        >
                            <td>{showNumberFormat(story.no)}</td>
                            <td className="content">
                                {story.ads_no > 0 && (
                                    <span className="ads">[광고] </span>
                                )}
                                {story.title}
                            </td>
                            <td className="user-name">{story.user_name}</td>
                            <td>{showType(story)}</td>
                            <td>{showNumberFormat(story.view_cnt)}</td>
                            <td>{showNumberFormat(story.reply_cnt)}</td>
                            <td>
                                {story.sort_no === 9999 ? '-' : story.sort_no}
                            </td>
                            <td>{showSimpleDate(story.reg_date)}</td>
                            <td>{showNumberFormat(story.limit_view_cnt)}</td>
                            <td>{showSimpleDate(story.limit_date)}</td>
                            {story.view == 'y' ? (
                                <td>{'공개'}</td>
                            ) : (
                                <td>{'비공개'}</td>
                            )}
                            <td className="red-font-color">
                                {' '}
                                {story.block_cnt || ''}
                            </td>
                            {story.report_cnt > 0 ? (
                                <td className="red-font-color">
                                    <button
                                        onClick={e => {
                                            e.stopPropagation(),
                                                getReportDetail(e, story.no);
                                        }}
                                        style={{
                                            color: 'black',
                                            backgroundColor: 'red',
                                            width: 100,
                                            height: 23,
                                            borderRadius: 10,
                                        }}
                                    >
                                        신고 확인
                                    </button>
                                </td>
                            ) : (
                                <td></td>
                            )}

                            <td>
                                {story.view == 'y' ? (
                                    <a
                                        href={`https://tosomebody.com/${story.addr_key}`}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        링크
                                    </a>
                                ) : (
                                    <>-</>
                                )}
                            </td>
                        </tr>
                    );
                })}
            </table>

            {/* <div className="paging-form"> */}
            {!setCheck.isChecked && (
                <table className="story-list-bottom">
                    <tr>
                        <td className="left">
                            <select onChange={setPerPage} value={numPerPage}>
                                {setPerPageList.map((num, idx) => {
                                    return (
                                        <option key={idx} value={num}>
                                            {num}
                                        </option>
                                    );
                                })}
                            </select>
                            개씩 보기, &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input
                                type="number"
                                id="idx_go_page"
                                value={currPage}
                                onChange={changePageValue}
                                // onChange={e =>
                                //     setCurrPage(Number(e.target.value))
                                // }
                            />{' '}
                            페이지로
                            <button onClick={() => movePage()}>이동하기</button>
                        </td>
                        <td align="right">
                            <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(1);
                                }}
                            >
                                처음 (1)
                            </div>
                            {currPage > 1 && (
                                <div
                                    className="page-nav-btn"
                                    onClick={() => {
                                        movePage(currPage - 1);
                                    }}
                                >
                                    이전
                                </div>
                            )}

                            <div className="page-nav-btn page-nav-curr">
                                {currPage}
                            </div>

                            {nextPage > 0 && (
                                <div
                                    className="page-nav-btn"
                                    onClick={() => {
                                        movePage(nextPage);
                                    }}
                                >
                                    다음 ({nextPage})
                                </div>
                            )}
                            {/* <div
                                className="page-nav-btn"
                                onClick={() => {
                                    movePage(Math.ceil(totalCnt / numPerPage));
                                }}
                            >
                                마지막 ({Math.ceil(totalCnt / numPerPage)})
                            </div> */}
                        </td>
                    </tr>
                </table>
            )}
            {/* </div> */}

            <div className="form-detail">
                <div className="detail-content">
                    <div className="detail-label">스토리 주소</div>
                    <div className="detail-addrkey">
                        <input
                            type="text"
                            value={addrKey}
                            readOnly
                            onFocus={e => {
                                e.currentTarget.select();
                                // document.execCommand('copy');
                            }}
                        />
                        <button onClick={() => utils.copyToClipboard(addrKey)}>
                            복사
                        </button>
                    </div>
                    <div className="detail-label">email</div>
                    <div className="detail-addrkey">
                        {strContent.user_email}
                    </div>
                    <div className="detail-label">content</div>
                    <div className="detail-textarea ">
                        <textarea
                            id="s_content"
                            value={strContent.content || ''}
                        ></textarea>
                    </div>
                </div>
                <div className="detail-form">
                    <div className="detail-label">user name</div>
                    <div className="detail-id">
                        <textarea
                            id="s_username"
                            value={strContent.user_name}
                        ></textarea>
                    </div>
                    <div className="detail-label">설정 기간</div>
                    <div className="detail-limit_date">
                        <textarea
                            id="s_regdate"
                            value={strContent.limit_date}
                            onChange={handlelimitdateChange}
                        ></textarea>
                    </div>
                    <div className="detail-label">설정 조회수</div>
                    <div className="detail-limit_view_cnt">
                        <textarea
                            id="s_limit"
                            value={strContent.limit_view_cnt}
                            onChange={handlelimitviewdateChange}
                        ></textarea>
                    </div>
                    <div className="detail-label">조회수</div>
                    <div className="detail-limit_view_cnt">
                        <textarea
                            id="s_view_cnt"
                            value={strContent.view_cnt}
                            onChange={handleviewCntChange}
                        ></textarea>
                    </div>
                    <div className="detail-label">차단수</div>
                    <div className="detail-limit_view_cnt">
                        <input
                            type="number"
                            value={strContent.block_cnt}
                            id="s_block_cnt"
                            onChange={handleblockCntChange}
                        />
                        <p>차단 5회 이상이면 홈 노출 세팅에서 제외</p>
                    </div>
                </div>

                <div className="detailkeyword-form">
                    {/* <div className="detail-label">keyword1</div>
                    <div className="detail-keyword1">
                        <textarea
                            id="s_keyword1"
                            value={strKeyword.keyword1 || ''}
                            onChange={handleKeyword1Change}
                        ></textarea>
                    </div>
                    <div className="detail-label">keyword2</div>
                    <div className="detail-keyword2">
                        <textarea
                            id="s_keyword2"
                            value={strKeyword.keyword2 || ''}
                            onChange={handleKeyword2Change}
                        ></textarea>
                    </div>
                    <div className="detail-label">keyword3</div>
                    <div className="detail-keyword3">
                        <textarea
                            id="s_keyword3"
                            value={strKeyword.keyword3 || ''}
                            onChange={handleKeyword3Change}
                        ></textarea>
                    </div>
                    <div className="detail-label">keyword4</div>
                    <div className="detail-keyword4">
                        <textarea
                            id="s_keyword4"
                            value={strKeyword.keyword4 || ''}
                            onChange={handleKeyword4Change}
                        ></textarea>
                    </div>
                    <div className="detail-label">keyword5</div>
                    <div className="detail-keyword5">
                        <textarea
                            id="s_keyword5"
                            value={strKeyword.keyword5 || ''}
                            onChange={handleKeyword5Change}
                        ></textarea>
                    </div> */}
                    <div className="detail-label">keywords</div>
                    <div className="detail-all-keyword">
                        <textarea
                            id="s_all_keyword"
                            value={strAllKeyword || ''}
                            onChange={handleAllKeywordChange}
                            rows={10}
                        ></textarea>
                        <br />
                        줄바꿈으로 구분 (콤마 X)
                    </div>
                </div>

                <div className="modify-submit">
                    <button
                        id="idx_modify"
                        className="btn modify"
                        onClick={() => modifyStory(strContent.no)}
                    >
                        수정
                    </button>

                    <button
                        id="idx_modify"
                        className="btn blind"
                        onClick={() => banStory(strContent.no)}
                    >
                        비공개처리
                    </button>

                    <button
                        id="idx_modify"
                        className="btn delete"
                        onClick={() => deleteStory(strContent.no)}
                    >
                        삭제
                    </button>
                </div>

                <div className="ban-submit"></div>
            </div>

            <div className="detail-label">첨부 이미지</div>
            <div className="detail-image-viewer">
                {imgFile.length &&
                    imgFile.map((imglist: any) => (
                        <div key={imglist}>
                            <div>
                                <img
                                    src={imglist}
                                    width="300"
                                    onLoad={loadImageEvent}
                                />
                                <div className="props">이미지</div>
                            </div>
                        </div>
                    ))}

                {imgFile.length == 0 && <div>첨부된 이미지가 없습니다.</div>}
            </div>

            {youtubeId != '' && (
                <>
                    <div className="detail-label">첨부 유튜브 영상</div>
                    <div className="detail-youtube-viewer">
                        {/** 9:16 비율 */}
                        <ReactPlayer
                            url={`https://www.youtube.com/watch?v=${youtubeId}`}
                            width="400px"
                            height="711px"
                        />
                    </div>
                </>
            )}

            {audioUrl !== '' && (
                <>
                    <div className="detail-label">첨부 오디오</div>
                    <div className="detail-audio-viewer">
                        <audio controls>
                            <source src={audioUrl} type="audio/mpeg" />
                        </audio>
                    </div>
                </>
            )}

            <div className="detail-label">댓글 ({replyList.length})</div>
            <div className="detail-reply-viewer">
                <table className="story-reply" width={'100%'}>
                    <colgroup>
                        <col width="70" />
                        <col width="250" />
                        <col width="*" />
                        <col width="120" />
                        <col width="60" />
                    </colgroup>
                    <tr>
                        <th>NO</th>
                        <th>작성자</th>
                        <th>내용</th>
                        <th>등록일</th>
                        <th>신고</th>
                    </tr>

                    {replyList.length === 0 && (
                        <tr>
                            <td colSpan={5}>댓글이 없습니다.</td>
                        </tr>
                    )}

                    {replyList.length &&
                        replyList.map((reply: any) => {
                            return (
                                <tr key={reply.reply_no}>
                                    <td>{reply.reply_no}</td>
                                    <td>
                                        {reply.user_name}
                                        <br />
                                        <span className="grey-font-color">
                                            ({reply.user_email})
                                        </span>
                                    </td>
                                    <td className="content">{reply.content}</td>
                                    <td>{reply.reg_date}</td>
                                    <td>{reply.report_cnt}</td>
                                </tr>
                            );
                        })}
                </table>
            </div>

            <div className="report-form">
                <label> 신고 리스트 </label>
                <table className="report-list">
                    <tr>
                        <th>NO</th>
                        <th>이름</th>
                        <th>신고 내용</th>
                        <th>등록일</th>
                    </tr>
                    {reportDetail.map((detail: any) => {
                        return (
                            <tr key={detail.no}>
                                <td>{detail.no}</td>
                                <td>{detail.user_name}</td>
                                <td>{detail.content}</td>
                                <td>{detail.reg_date}</td>
                            </tr>
                        );
                    })}
                </table>
            </div>
        </div>
    );
});

export default StoryDetail;
