import React from 'react';
import './res/scss/main.scss';

import MainLayout from './components/MainLayout';

function App() {
    return (
        <>
            <MainLayout />
        </>
    );
}

export default App;
