import { observer } from 'mobx-react';
import store from '../../store';
import * as LocalStore from '../../store/LocalStore';
import * as ApiService from '../../api/ApiService';
import React, { useEffect } from 'react';
import '../../res/scss/login-page.scss';

const LoginPage = observer(() => {
    useEffect(() => {
        console.log('LoginPage');

        initLoad();
    }, []);

    const initLoad = async () => {
        console.log('initLoad');

        const userId = document.getElementById('user_id') as HTMLInputElement;

        userId.focus();
    };

    const login = async () => {
        const userId = document.getElementById('user_id') as HTMLInputElement;
        const userPw = document.getElementById('user_pw') as HTMLInputElement;

        if (userId.value === '') {
            alert('ID를 입력해주세요.');
            userId.focus();
            return;
        }
        if (userPw.value === '') {
            alert('PASSWORD를 입력해주세요.');
            userPw.focus();
            return;
        }

        const data = {
            userId: userId.value,
            userPw: userPw.value,
        };

        const authData = await ApiService.login(data);
        if (authData !== null) {
            LocalStore.setAuthData(authData);
            window.location.reload();
        } else {
            alert('로그인에 실패하였습니다.');
        }
    };

    const checkKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            login();
        }
    };

    return (
        <div className="login-container">
            <div className="login-box">
                <div className="login-title">LOGIN</div>
                <div className="login-form">
                    <div className="form-group">
                        <input
                            id="user_id"
                            type="text"
                            placeholder="ID"
                            onKeyDown={checkKeyDown}
                        />
                    </div>
                    <div className="form-group">
                        <input
                            id="user_pw"
                            type="password"
                            placeholder="PASSWORD"
                            onKeyDown={checkKeyDown}
                        />
                    </div>
                    <div className="login-btn">
                        <button onClick={login}>LOGIN</button>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default LoginPage;
