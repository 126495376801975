import { observer } from 'mobx-react';
import store from '../../store';
import { useEffect } from 'react';
import StoryDetail from './StoryDetail';
import NewStory from './NewStory';
import ServerScheduleJob from './ServerScheduleJob';
import UserList from './UserList';
import ReportReplyList from './ReportReplyList';
import Advertisers from './Advertisers';
import AdsList from './AdsList';
import Notice from './Notice';
import Qna from './Qna';

const BodyContent = observer(() => {
    const { adminInfo } = store;

    useEffect(() => {
        console.log(adminInfo.getLeftMenu());
    }, []);

    return (
        <>
            {adminInfo.getLeftMenu() === 'story' && <StoryDetail />}
            {adminInfo.getLeftMenu() === 'new_story' && <NewStory />}
            {adminInfo.getLeftMenu() === 'report_reply' && <ReportReplyList />}
            {adminInfo.getLeftMenu() === 'user_list' && <UserList />}
            {adminInfo.getLeftMenu() === 'notice' && <Notice />}
            {adminInfo.getLeftMenu() === 'qna' && <Qna />}
            {adminInfo.getLeftMenu() === 'adsr_list' && <Advertisers />}
            {adminInfo.getLeftMenu() === 'ads_list' && <AdsList />}
            {adminInfo.getLeftMenu() === 'server_schedule_job' && (
                <ServerScheduleJob />
            )}
        </>
    );
});

export default BodyContent;
